import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Typography, Dropdown, Space, Button } from "antd";
import Color from '../../colors.scss';
import { DownOutlined } from "@ant-design/icons";
import logo from "../../assets/svg/logo.svg";
import { useNavigate } from "react-router-dom";
import "./header.scss";
import MobileHeaderLayout from "../layouts/mobileHeader.layout.js";
import FlowType from "../../enums/flowType.enum.js";
import Breakpoint from '../../enums/breakpoint.enum'
import Role from "../../enums/role.enum.js";
import useWidth from "../../hooks/useWidth.hook.js";
import { UserContext } from "../../contexts/user.context.js"; 
import { AnnouncementBanner } from "../announcementBanner/announcementBanner.component";
const { Text } = Typography

export const Header = ({ isPro=false, hasMobile=true }) => {
  const navigate = useNavigate();
  const [headerItems, setHeaderItems] = useState([]);
  const { token, currentUser, instalabMembership } = useContext(UserContext)
  const width = useWidth()

  useEffect(() => {

    const dashboardItem = {
      title: 'Dashboard',
      onClick: () => navigate('/')
    }

    const giftItem = {
      title: <>Gift Instalab</>,
      onClick: () => navigate(`/flow/${FlowType.GIFT}`)
    }

    const logoutItem = {
      title: "Logout",
      onClick: onLogout,
    };

    const loginItem = {
      title: "Login",
      onClick: () => navigate("/login"),
    };

    const testItem = {
      title: "Popular Tests",
      dropdownItems: [
        {
          key: 'longevity',
          label: (
            <a onClick={() => navigate("/longevity-panel")}>
              <span style={{fontWeight:600}}>Most start with:</span> <br/>Longevity Blood Panel
            </a>
          ),
        },
        {
          type: 'divider',
        },

        {
          key: 'cac',
          label: (
            <a onClick={() => navigate(`/cac`)}>
              Coronary Calcium Scan
            </a>
          )
        },
        // {
        //   key: 'fertility',
        //   label: (
        //     <a onClick={() => navigate(`/fertility`)}>
        //       Fertility Hormone Test
        //     </a>
        //   )
        // },
        {
          key: 'galleri',
          label: (
            <a onClick={() => navigate(`/galleri-test`)}>
              Galleri Cancer Detection Test
            </a>
          ),
        },
        {
          key: 'sleep',
          label: (
            <a onClick={() => navigate(`/sleep-apnea-test`)}>
              Sleep Apnea Test
            </a>
          ),
        },
        {
          key: 'testosterone',
          label: (
            <a onClick={() => navigate(`/testosterone`)}>
              Testosterone Panel
            </a>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: 'view-all',
          label: (
            <a onClick={() => navigate(`/catalog`)}>
              View All Tests
            </a>
          ),
        }

      ]
    }

  
    let patientLinks = !isPro ? [testItem, giftItem] : []

    if (!token) {
      setHeaderItems([...patientLinks, loginItem]);
    } else if (currentUser?.role === Role.PATIENT) {
      setHeaderItems([dashboardItem, ...patientLinks, logoutItem]);
    } else if (
      currentUser?.role === Role.ADMIN ||
      currentUser?.role === Role.PROVIDER ||
      currentUser?.role === Role.STAFF ||
      currentUser?.role === Role.PHLEBOTOMIST
    ) {
      setHeaderItems([logoutItem]);
    }
  }, [currentUser, isPro, token]);

  const onLogout = () => {
    navigate("/logout");
  };

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const becomeMember = () => {
    navigate(`/flow/${FlowType.MEMBER}`)
  }

  const signUp = () => {
    navigate(`/signup?tab=signup`)
    // navigate(`/flow/${FlowType.INTAKE}`)
  }

  const HeaderItem = ({ title, path, onClick, dropdownItems }) => {
    if (dropdownItems) {

      return (
        <div className="header-item">
          <Dropdown menu={{ items: dropdownItems }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {title}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      );
    }

    else 
      return (
        <div className="header-item">
          <a
            key={`header-${title}`}
            onClick={() => {
              if (path) {
                window.open(path, "_blank");
              } else {
                onClick();
              }
            }}
          >
            {title}
          </a>
        </div>
      );
  };

  const DesktopHeader = () => {
    return (
      <>
      <AnnouncementBanner />

      <Row className="desktop-header">
        <Col>
          <div 
            className="logo-container"
            onClick={() => navigate((isPro && !token) ? '/pro' : '/')}
          >
            <img
              src={logo}
              alt="logo"
              className="logo"
            /> 
            <Text className="logo-name">
              {isPro ? <>Instalab<span style={{ color: Color.success }}>Pro</span></> :  'Instalab'}
              {/* {isPro ? <>Instalab<span style={{ color: Color.success }}>Pro</span></> : MembershipHelper.isPremium(instalabMembership) ? <>Instalab <Tag color="success">Premium</Tag></>  : 'Instalab'} */}
            </Text>
          </div>
        </Col>

        <Col>
          <div className="header-item-list">
            {headerItems.map(({ title, path, onClick, dropdownItems }, index) => (
              <HeaderItem
                key={`header-item-${index}`}
                index={index}
                title={title}
                path={path}
                onClick={onClick}
                dropdownItems={dropdownItems}
              />
            ))}
            {(token && !instalabMembership && currentUser?.role === Role.PATIENT) && <Button type="default" onClick={becomeMember} style={{fontWeight:600}}>Become a Member</Button>}
            {!token && 
              <><Button type="default" onClick={signUp} style={{fontWeight:600}}>Sign Up</Button></>
            }
          </div>
          
        </Col>
      </Row>
      </>
    );
  };

  return (hasMobile || width) && <>
    {(!hasMobile || width > Breakpoint.LG) ? <DesktopHeader /> : <MobileHeaderLayout isPro={isPro}  />}
  </>
};
