import { Typography }  from 'antd'
import './memberConfirmed.scss'
import { CloseOutlined } from '@ant-design/icons'
import Breakpoint from "../../../../enums/breakpoint.enum";
import ProductTypeCode from "../../../../enums/productTypeCode.enum"
import { useNavigate } from 'react-router-dom';
import useWidth from '../../../../hooks/useWidth.hook';
import FlowType from '../../../../enums/flowType.enum';
import Panels from "../../../panels/panels.component";
import { UserContext } from '../../../../contexts/user.context';
import { useContext } from 'react';
const { Paragraph } = Typography

const productTypeCodes = [
  ProductTypeCode.LONGEVITY_PANEL,
  ProductTypeCode.GALLERI,
  ProductTypeCode.CTCALCIUM,
  ProductTypeCode.WATCHPAT_ONE
]

const MemberConfirmed = ({ footer, instalabMembership }) => {
  const width = useWidth()
  const navigate = useNavigate()

  return (
    <div 
      className="member-confirmed"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >
      <div 
        className="member-confirmed-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <Paragraph className="member-title">
          Welcome to {instalabMembership?.membershipType?.title}! 
        </Paragraph>
        <Paragraph className="member-description">
          We're thrilled to have you as a member. You can reach out anytime to <a style={{color: `gray`}} href="mailto:concierge@instalab.com">concierge@instalab.com</a> with any questions or requests.
          
          <br/>
          <CloseOutlined className="close-icon" />

          <h4>Getting Started</h4>
          If you haven’t done comprehensive blood work in the past year, let’s get that taken care of first. This step ensures we have the most up-to-date information on your health:
          
          {footer({ 
            buttonText: "Get Longevity Panel",
            buttonCheck: false,
            buttonStyle: {
              height: 50,
              fontSize: 18,
              width: 300,
              margin: '40px auto 0 auto',
              display: 'block',
            },
            enterStyle: {
              display: 'none',
            },
            onCustomSubmit: () => navigate(`/longevity-panel`)
          })} 
          <br/><CloseOutlined className="close-icon" />

          <h4>Book Your First Consult</h4>
          Ready to take the next step? Schedule your session with one of our longevity physicians today:

          {footer({ 
            buttonText: "Schedule Consult",
            buttonCheck: false,
            buttonStyle: {
              height: 50,
              fontSize: 18,
              width: 300,
              margin: '40px auto 0 auto',
              display: 'block',
            },
            enterStyle: {
              display: 'none',
            },
            onCustomSubmit: () => navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`)
          })} 
        </Paragraph>


        <Panels productTypeCodes={productTypeCodes} />
      </div>
    </div>
  )
}

export default MemberConfirmed