import { 
  Recommendation, 
  RecommendationKind, 
  Diet, 
  ReportSection, 
  TestCode, 
  ReferralType, 
} from "../enums/index.enum.js";
import { ProductCode } from "../data/product.data";
import { Reference } from "../components/universalReport/reference/reference.component";

const EmailConcierge = ({subject, isMember, text}) => {
  const email = isMember ? "concierge@instalab.com" : "support@instalab.com"

  if (text) {
    return (
      <div className="contact-concierge">Email <a href={`mailto:${email}?Subject=${subject}`}>{email}</a> {text}
      </div>     
    )
  }
  else 
    return (
      <div className="contact-concierge">
      Email <a href={`mailto:${email}?Subject=${subject}`}>{email}</a> for assistance in ordering these tests. We can help arrange a visit to a nearby patient service center or have a mobile phlebotomist come to your home for the blood draw.
      </div>     
    )
}

const foodData = {
  HIGH_PURINE: [
    {
      food: "Yeasts and yeast extracts: beer and alcoholic beverages",
    },
    {
      food: "Organ meats: brains, heart, kidney, liver, and sweetbreads",
    },
    {
      food: "Wild game meats: goose and duck",
    },
    {
      food: "Anchovies, sardines, scallops, and mussels",
    },
    {
      food: "Tuna, codfish, herring, and haddock"    
    }
  ],
  HIGH_SATURATED_FATS: [
    {
      food: "Butter",
      exclude: [Diet.VEGAN]
    },
    {
      food: "Margarine: especially stick or hard margarine",
      exclude: [Diet.VEGAN]
    },
    {
      food: "Fatty cuts of red meat: beef, lamb, pork",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN, Diet.PESCATARIAN]
    },
    {
      food: "Processed meats: sausages, hot dogs, bacon",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN, Diet.PESCATARIAN]
    },
    {
      food: "Full-fat dairy products: whole milk, cheese, cream, ice cream",
      exclude: [Diet.VEGAN]
    },
    {
      food: "Fried foods: french fries, onion rings",
    },
    {
      food: "Deep-fried snacks: potato chips, corn chips, fried tortilla chips",
    },
    {
      food: "Pastries, cakes, and cookies made with butter or lard",
      exclude: [Diet.VEGAN]
    },
    {
      food: "Palm oil and palm kernel oil: often found in processed foods",
    }
  ],
  HIGH_UNSATURATED_FATS: [
    {
      food: "Avocados"
    },
    // {
    //   food: "Fatty fish: salmon, mackerel, sardine",
    //   exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    // },
    {
      food: "Olive oil: extra virgin, virgin, or regular"
    },
    {
      food: "Raw and unsalted nuts: almonds, pecans, hazelnuts, macadamia nuts, cashews, pistachios"
    },
    {
      food: "Nut butters (natural, unsweetened): almond butter, peanut butter, cashew butter"
    },
    // {
    //   food: "Seeds: chia seeds, flaxseeds, pumpkin seeds, sesame seeds"
    // },
    {
      food: "Olives: black or green"
    },
    // {
    //   food: "Canola oil"
    // },
    // {
    //   food: "Sunflower oil"
    // },
    // {
    //   food: "Soybean oil"
    // }
  ],
  HIGH_FIBER: [
    {
      food: "Whole grains: oats, quinoa, brown rice, whole wheat bread",
    },
    {
      food: "Legumes: black beans, lentils, chickpeas"
    },
    {
      food: "Fruits: apples, berries, pears, oranges, bananas"
    },
    {
      food: "Vegetables: broccoli, brussels sprouts, carrots, spinach"
    },
    {
      food: "Seeds: chia seeds, flaxseeds"
    },
    {
      food: "Nuts: almonds, walnuts"
    },
    {
      food: "Whole grain cereals: bran flakes, shredded wheat"
    },
    {
      food: "Barley"
    },
    {
      food: "Buckwehet"
    },
    {
      food: "Amaranth"
    }
  ],
  HIGH_VISCOUS_FIBER: [
    {
      food: "Whole grains: barley, brown rice, oatmeal, oat bran and quinoa",
      size: "½ cup cooked",
      amount: "1-2g"
    },
    {
      food: "Plant protein foods: lima beans, kidney beans, black beans, navy beans, pinto beans, black-eyed peas, chickpeas, lentils and split peas",
      size: "½ cup",
      amount: "1-3g"
    },
    {
      food: "Vegetables: broccoli, cauliflower, carrows, brussel sprouts, cabbage, green beans, okra, parsnips, turnips",
      size: "½ cup cooked or 1 cup raw",
      amount: "1-3g"
    },
    {
      food: "Starchy vegetables: 1 medium sweet or white potato, 1/2 cup winter squash, 1/2 cup green peas",
      amount: "2-3g"
    },
    {
      food: "Fruits: apple, banana, orange, peach, or pear",
      amont: "1-3g",
      size: "1 medium"
    },
    {
      food: "Foods with healthy fats: ¼ avocado, 1 tbsp whole chia seeds, 2 tbsp ground flax seeds, ¼ cup sunflower seeds",
      amount: "1-3g"
    }
  ],
  HIGH_OMEGA3: [
    {
      food: "Fatty fish: anchioves, herring, mackerel (atlantic & pacific), oysters (pacific), sablefish (black cod), salmon (atlantic, chinook, coho), sardines (atlantic & pacific), swordfish, tuna (albacore/white)",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    },
    {
      food: "Other seafood: alaskan pollack, mussels, sea bass, squid, tilefish, shrimp, crab, scallops",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    },
    {
      food: "Chia seeds"
    },
    {
      food: "Flaxseeds and flaxseed oil"
    },
    {
      food: "Walnuts"
    },
    {
      food: "Hemp seeds and hemp seed oil"
    },
    {
      food: "Soybeans and soybean oil"
    },
    {
      food: "Canola oil"
    },
    {
      food: "Edamame, young soybeans"
    },
    {
      food: "Algal oil: derived from algae, a plant-based source of omega-3s"
    },
  ],
  HIGH_SODIUM: [
    {
      food: "Processed meats: ham, bacon, sausage, deli meats",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    },
    {
      food: "Canned soups and broths"
    },
    {
      food: "Salty snacks: potato chips, pretzels, salted popcorn"
    },
    {
      food: "Fast food items: burgers, fries",
    },
    {
      food: "Condiments and sauces: ketchup, soy sauce, salad dressings"
    },
    {
      food: "Cheese and other dairy products",
      exclude: [Diet.VEGAN]
    },
    {
      food: "Pickled foods: pickles, sauerkraut, olives"
    },
    {
      food: "Instant noodles and packaged pasta meals"
    },
    {
      food: "Frozen meals and pre-packaged dinners"
    }
  ],
  HIGH_POTASSIUM: [
    {
      food: 'Beans (lima, black bean, kidney beans), acorn or butternut squash'
    },
    {
      food: 'Potatoes (with skin)'
    },
    {
      food: "Bananas"
    },
    {
      food: "Avocados"
    },
    {
      food: "Spinach"
    },
    {
      food: "Tomatoes"
    },
    {
      food: "Nuts without salt"
    }
  ],
  HIGH_REFINED_CARBS: [
    {
      food: "White bread"
    },
    {
      food: "White rice"
    },
    {
      food: "Pasta made from refined flour"
    },
    {
      food: "Sugary cereals"
    },
    {
      food: "Pastries: cakes, cookies, doughnuts"
    },
    {
      food: "Soda and other sugary drinks"
    },
    {
      food: "Candy and chocolates"
    },
    {
      food: "Processed snack foods: chips, crackers, pretzels"
    },
    {
      food: "Sugary desserts: ice cream, pies, puddings"
    }
  ],
  HIGH_VITAMIN_B9: [
    {
      food: "Legumes: Lentils, chickpeas, black beans, kidney beans, edamame are a powerhouse for folate, as well as being high in protein and fiber."
    },
    {
      food: "Dark, leafy greens: Spinach, collard greens, turnip greens, romaine lettuce, mustard greens are among the best sources of folate."
    },
    {
      food: "Asparagus: Also a good source of fiber, vitamins A, C, and K."
    },
    {
      food: "Fortified cereals: Many breakfast cereals are fortified with essential vitamins, including folate but always check to avoid sugar."
    },
    {
      food: "Beets: Beets are not only rich in folate but also iron and fiber."
    },
    {
      food: "Citrus fruits: Oranges, lemons, limes and grapefruits add a good dose of folate, along with vitamin C."
    },
    {
      food: "Brussels Sprouts: High in folate, vitamin K, and fiber."
    },
    {
      food: "Nuts and seeds: Sunflower seeds, almonds, peanuts are great for snacking and adding to meals for a boost of folate."
    },
    {
      food: "Avocado: Also good source of healthy unsaturated fats."
    },
    {
      food: "Broccoli: Also good source of vitamins C and K."
    }

  ],
  HIGH_VITAMIN_B12: [
    {
      food: "Clams"
    },
    {
      food: "Liver: especially beef liver"
    },
    {
      food: "Trout, salmon, tuna, sardines"
    },
    {
      food: "Mussels, oysters, shellfish"
    },
    {
      food: "Beef, lamb, pork",
      exclude: [Diet.PESCATARIAN]
    },
    {
      food: "Chicken, turkey, poultry",
      exclude: [Diet.PESCATARIAN]
    },
    {
      food: "Eggs"
    },
    {
      food: "Milk, cheese, yougurt"
    }
  ],
  HIGH_METHIONINE: [
    {
      food: "Crab, cooked (1 leg)",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    },
    {
      food: "Tuna, canned (3oz)",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    },
    {
      food: "Beef, leen cooked (3oz)",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN, Diet.PESCATARIAN]
    },
    {
      food: "Chicken breast, cooked (3oz)",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN, Diet.PESCATARIAN]
    },
    {
      food: "Salmon (3oz)",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    },
    {
      food: "Lobster, cooked (1 cup)",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    },
    {
      food: "Pork, cooked (3oz)",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN, Diet.PESCATARIAN]
    },
    {
      food: "Turkey, roasted(3oz)",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN, Diet.PESCATARIAN]
    },
    {
      food: "Cod fish (3oz)",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    },
    {
      food: "Ham, cooked (3oz)",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN, Diet.PESCATARIAN]
    },
    {
      food: "Shrimp, cooked (3oz)",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    },
    {
      food: "Eggs (2)",
      exclude: [Diet.VEGAN]
    },
    {
      food: "Brazil nuts (1 oz)"
    },
    {
      food: "Soy nuts, dry roasted (1 cup)"
    },
    {
      food: "Milk 1% (1 cup)",
      exclude: [Diet.VEGAN]
    },
    {
      food: "Yogurt, low fat, fruit (6oz)",
      exclude: [Diet.VEGAN]
    }
  ],
  HIGH_SELENIUM: [
    {
      food: "Brazil nuts, dried: 543.5 mcg / oz (6-8 nuts)"
    },
    {
      food: "Rockfish, cooked: 113.5 mcg / 1 fillet",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    },
    {
      food: "Tuna, yellowfin, cooked: 92 mcg / 3 oz",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    },
    {
      food: "Halibut, cooked: 88.1 mcg / ½ fillet",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    },
    {
      food: "Salmon, red sockeye with skin 82 mcg / 1 filet",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN]
    },
    {
      food: "Chicken liver, cooked 75 mcg / 3 oz",
      exclude: [Diet.VEGAN, Diet.VEGETARIAN, Diet.PESCATARIAN]
    },
    {
      food: "Wheat flour, whole grain: 74.2 mcg / 1 cup",
    },
    {
      food: "Potatoes, mashed, ready-to-eat 61.6 mcg / 1 cup",
    },
    {
      food: "Couscous, cooked: 43.2 mcg / 1 cup",
    },
    {
      food: "Wheat flour, white, all-purpose, enriched 43 mcg /1 cup",
    },
  ]
}

const RecommendationData = [
  { 
    code: Recommendation.AVOID_HIGH_PURINE,
    kind: RecommendationKind.NUTRITION,
    title: () => 'Avoid high purine foods',
    description: () => (      
      <p>
        While all plants and meats contain purines, certain foods contain higher concentrations of purine like beer, organ and wild game meats.
      </p>
    ),
    foodList: () => {
      return {
        items: [{
          foods: foodData.HIGH_PURINE, 
          title: "Foods to avoid"
      }]
      }
    }
  },
  { code: Recommendation.STOP_SMOKING,
    kind: RecommendationKind.LIFESTYLE,
    title: () => `Stop smoking`,
    description: () => (
      <p>
        Don't underestimate the impact of smoking on your cardiovascular
        health. Within one year of stopping, your risk of dying from heart
        disease{" "}
        <Reference
          content="drops by ~50%"
          url="https://jamanetwork.com/journals/jama/fullarticle/2748507"
        />{" "}
        and continues to decline over time.
      </p>
    ),
  },
  { code: Recommendation.LOWER_SATURATED_FATS,
    kind: RecommendationKind.NUTRITION,
    title: () => "Replace saturated fats with unsaturated fats",
    description:  ({diet}) => {
      return <>
        {diet?.includes(Diet.VEGAN) ? 
          <p>
            Saturated fats present in foods like coconut oil, palm oil and processed plant-based foods, lead to higher apoB and LDL cholesterol levels. Substitute these with foods high in unsaturated fats like olive oil, avocados and nuts.
          </p>
        : (diet?.includes(Diet.VEGAN) || diet?.includes(Diet.PESCATARIAN)) ? 
          <p>
            Foods high in saturated fats like butter, cheese and high-fat dairy products lead to higher apoB and LDL cholesterol levels. 
            Substitute these with foods high in unsaturated fats like olive oil, avocados and nuts. 
          </p>
        :  <p>
              Foods high in saturated fats like fatty and processed meats, butter and cheese can raise apoB and LDL cholesterol levels. Instead, opt for foods rich in unsaturated fats like olive oil, avocados, and nuts.            
            </p> 
          } 
          <p>Unsaturated fats are categorized into monounsaturated (MUFAs) and polyunsaturated (PUFAs) fats, with much stronger evidence supporting the cardiovascular benefits of MUFAs. So prioritizing MUFAs is recommended to maximize health benefits and reduce cardiovascular risk.</p>
      </>},
    foodList: ({ diet }) => {
      return {
        items: [
          {foods: foodData.HIGH_UNSATURATED_FATS, title: "Eat More"},
          {foods: foodData.HIGH_SATURATED_FATS, title: "Eat Less"}
        ],
        restrictions: diet
      }
    }
  },
  { code: Recommendation.INCREASE_SOLUBLE_FIBER,
    kind: RecommendationKind.NUTRITION,
    title: ({supplementFiberAlready}) => <>{supplementFiberAlready? "Continue viscous fiber supplements" : "Add soluble (viscous) fiber to diet or supplement"}</>,
    description:() =>  (
      <p>Aim for 10 grams daily of viscous fiber. It's a type of soluble fiber that forms a gel-like substance in water, which slows down digestion and impedes cholesterol absorption, helping regulate cholesterol levels. Examples are pectins, beta-glucans, glucomannan, guar gum, and psyllium.</p>
    ),
    foodList: () => {
      return {
        items: [
          {supplement: ProductCode.VISCOUS_FIBER, title: "Supplement"}, 
          {foods:foodData.HIGH_VISCOUS_FIBER, title:"Foods"}
        ]
      }
    }
  },
  { code: Recommendation.VISCIOUS_FIBER,
    kind: RecommendationKind.NUTRITION,
    title: ({supplementFiberAlready}) => <>{supplementFiberAlready? "Continue viscous fiber supplements" : "Increase viscous fiber intake"}</>,
    description:() =>  (
      <p>
        Aim for at least 10 grams daily of viscous fiber. It's a type of soluble fiber that forms a gel-like substance in water, which slows down digestion and impedes cholesterol absorption, helping regulate cholesterol levels. Examples are beta-glucans in oats and barley and pectins in apples and citrus fruits. 
      </p>
    ),
    foodList: () => {
      return {
        items: [
          // {supplement: ProductCode.VISCOUS_FIBER, title: "Supplement"}, 
          {foods:foodData.HIGH_VISCOUS_FIBER, title:"Eat More"}
        ]
      }
    },
    // products: [ProductCode.VISCOUS_FIBER],
    // hideProduct: true

  },
  { code: Recommendation.BERBERINE,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({supplementBerberineAlready}) => <>{supplementBerberineAlready? "Continue berberine supplement" : "Take beberine supplement"}</>,
    description:() =>  (
      <>
      <p>
      Berberine is a natural compound derived from plants like goldenseal and Oregon grape root. Studies indicate that it can reduce ApoB and LDL cholesterol levels by 20-25%. Its primary action is through the activation of AMPK (adenosine monophosphate-activated protein kinase), an enzyme that plays a crucial role in lipid and glucose metabolism.
      </p>
      <p>
        <u>Dosage</u>: Take 1000 mg daily with meals for optimal absorption and effectiveness.
      </p>
      <p>
        <u>Side Effects</u>: Berberine may interact with medications, particularly those affecting blood sugar and blood pressure, so consult your physician before starting if you are on other treatments.
      </p>
      </>
    ),
    products: [ProductCode.BERBERINE],

  },
  { code: Recommendation.RED_YEAST_RICE,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({supplementRedYeastRiceAlready}) => <>{supplementRedYeastRiceAlready? "Continue red yeast rice supplement" : "Take red yeast rice supplement"}</>,
    description:() =>  (
      <>
      <p>
       Red yeast rice is a fermented rice product containing monacolin K, a natural compound similar to the active ingredient in some cholesterol-lowering statin drugs. Research shows that red yeast rice can reduce ApoB and LDL cholesterol by 20-30%, making it a popular natural option for managing cholesterol levels.
      </p>
      <p>
        <u>Dosage</u>: Take 1,200 mg daily with meals for optimal absorption and effectiveness.
      </p>
      <p>
        <u>Side Effects</u>: Red yeast rice may cause mild side effects like muscle discomfort or digestive upset. It may also interact with other medications so consult your physician before starting, especially if you're on other cholesterol-lowering treatments.
      </p>
      </>
    ),
    products: [ProductCode.RED_YEAST_RICE],

  },
  { code: Recommendation.HOMOCYSTEINE_RESIST,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({isTakingBVitamins}) => <>{isTakingBVitamins ? `Continue multi-B vitamin supplement` : `Take multi-B vitamin supplement`}</>,
    description: ({isTakingBVitamins, supplementB12Already, supplementB9Already, supplementB6Already, lowVitaminB12, lowVitaminB9, hasMacrocytosis, isMeatEater, diet}) => ( 
      <>
        <p>Boosting key B vitamins - namely B6, B9, and B12 - helps convert
        homocysteine into other products, resulting in lower homocysteine levels.</p> 

        {lowVitaminB12 && lowVitaminB9 && (
          <p>
            This will also help increase your <span className="view-test" id={`view-test-${TestCode.FOLATE}`} text="vitamin B9" /> and <span className="view-test" id={`view-test-${TestCode.VITAMIN_B12}`} text="vitamin B12" />
            {!isMeatEater && <>, which may be low due to your {diet.some(item => item === Diet.VEGETARIAN) ? <>vegetarian</> : <>vegan</>} diet, </>} to optimal levels 
            {hasMacrocytosis && <> and bring down your <span className="view-test" id={`view-test-${TestCode.MCV}`} text="MCV" />, which is likely high due to vitamin B deficiencies</>}.
          </p>
        )}


          {!lowVitaminB12 && lowVitaminB9 && (
          <p>
            This will also help increase your <span className="view-test" id={`view-test-${TestCode.FOLATE}`} text="vitamin B9" /> to optimal levels.
          </p>
        )}

        {lowVitaminB12 && !lowVitaminB9 && (
          <p>
            This will also help increase your <span className="view-test" id={`view-test-${TestCode.VITAMIN_B12}`} text="vitamin B12" /> to an optimal
            level,{" "}
            {isMeatEater
              ? "which is low"
              : <>which may be low due to your {diet.some(item => item === Diet.VEGETARIAN) ? <>vegetarian</> : <>vegan</>} diet</>}
            .
          </p>
        )}

        {isTakingBVitamins 
          ? <p>Since you're already taking these supplements, consider doubling your current dosage and monitor impact on your levels in 3 months.</p>
          : (supplementB12Already || supplementB9Already || supplementB6Already) &&  <p>Since you already include specific B vitamins in your current supplementation, be sure to continue taking at least the same amount of that specific B vitamin to avoid reducing your overall vitamin B levels.</p>
        } 
      </>
    ),
    products: ({isAthlete}) => isAthlete ? [ProductCode.VITAMIN_B9_1333_B12_800_ATHLETE] : [ProductCode.HOMOCYSTEINE_RESIST],
  },
  { code: Recommendation.HOMOCYSTEINE_B12_ONLY,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({supplementB12Already}) => <>{supplementB12Already ? "Continue vitamin B12 supplements" : "Take vitamin B12 supplements"}</>,
    description: ({supplementB12Already, isMeatEater, diet, b12Up, nearOptimalVitaminB12, isAthlete}) => (
      <>
        <p>
        Boosting your B12 will help convert homocysteine into other products, resulting in lower homocysteine levels.  
        This will also help increase your <span className="view-test" id={`view-test-${TestCode.VITAMIN_B12}`} text="low vitamin B12" /> to an optimal level
        {!isMeatEater && <>, which may be low due to your {diet.some(item => item === Diet.VEGETARIAN) ? <>vegetarian</> : <>vegan</>} diet</>}.
        </p>
        
        {(supplementB12Already && b12Up) && <p>Since you're already taking this supplement and your vitamin B12 is increasing, keep your current dosage and monitor impact on your levels in 3 months.</p>}
        {(supplementB12Already && !b12Up && !nearOptimalVitaminB12) && <p>Since you're already taking this supplement, double your current dosage and monitor impact on your levels in 3 months.</p>}
        {(supplementB12Already && !b12Up && nearOptimalVitaminB12) && <p>Since you're already taking this supplement and near optimal, maintain your current dosage and monitor impact on your levels in 3 months.</p>}
        {!supplementB12Already ? isAthlete  ? <p>Take at least 1333 mcg of vitamin B12 daily until you reach optimal levels.</p> : <p>Take 5000 mcg of vitamin B12 daily, ideally as a chewable sublingual or liquid supplement.</p> : null}
      </>
    ),
    products: ({isAthlete}) => isAthlete ? [ProductCode.VITAMIN_B9_1333_B12_800_ATHLETE] : [ProductCode.VITAMIN_B12]
  },
  { code: Recommendation.HOMOCYSTEINE_B9_ONLY,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({supplementB9Already}) => <>{supplementB9Already ? "Continue vitamin B9 supplements" : "Take vitamin B9 supplements"}</>,
    description: ({supplementB9Already, hasSIBO, hasThalassemia}) => (
      <>
        <p>
        Boosting your vitamin B9 (aka folate) will help convert homocysteine into other products, resulting in lower homocysteine levels.  
        This will also help increase your <span className="view-test" id={`view-test-${TestCode.FOLATE}`} text="low vitamin B9" />{hasThalassemia && <>, which is likely due to your thalassemia condition, </>}{hasSIBO && <>, which may be low to due SIBO, </>} to an optimal level</p>

        {supplementB9Already 
          ? <p>Since you're already taking this supplement, double your current dosage and monitor impact on your levels in 3 months.</p>
          : <p>Take at least 1000 mcg of methylfolate, the most bioavailable forms of vitamin B9, daily with food.</p>
        }
      </>
    ),
    products: ({isAthlete}) => isAthlete ? [ProductCode.VITAMIN_B9_1333_B12_800_ATHLETE] : [ProductCode.VITAMIN_B9]
  },
  { code: Recommendation.OMEGA_3S_TG,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({supplementOmega3sAlready}) => (<>{supplementOmega3sAlready ? <>Continue taking omega-3 supplements</> :<>Take omega-3 supplements</>}</>),
    description: ({TG, supplementOmega3sAlready}) => (
      <p>
        { ((TG < 100) && supplementOmega3sAlready) ? <>Maintain your current dosage to help keep your triglycerides at optimal levels. </>
        
        : <>

        Omega-3 fatty acids can lower your triglycerides by up to 45%. You
        can find them in foods like fatty fish and flaxseed. But the easiest way to increase your intake
        is taking supplements. 

        <>{TG<200 && <> Take at least 1 gram per day with a meal.</>}
        {(TG>=200 && TG<500)  && <> Take at least 2 grams per day with a meal.</>}
        {TG>=500 && <> Take at least 4 grams per day with a meal.</>}</>
        </>
        }
      </p>
    ),
    foodList: ({ isVegan, isVegetarian, isAthlete }) => {
      if (isVegan || isVegetarian) {
        return {
          items: [
            {supplement: ProductCode.OMEGA_3_VEGAN, title: "Supplement"}, 
            {foods:foodData.HIGH_OMEGA3, title:"Foods"}
          ],
          restriction: isVegetarian ? [Diet.VEGETARIAN] :  [Diet.VEGAN]
        }
      } else {
        return {
          items: [
            {supplement: isAthlete ? ProductCode.OMEGA_3_ATHLETE : ProductCode.OMEGA_3, title: "Supplement"}, 
            {foods:foodData.HIGH_OMEGA3, title:"Foods"}
          ]
        }
      }
    },
    products: ({isAthlete}) => isAthlete ? [ProductCode.OMEGA_3_ATHLETE] : [ProductCode.OMEGA_3] ,
    hideProduct: true,
  },
  { code: Recommendation.OMEGA_3S,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({supplementOmega3sAlready}) => (<>{supplementOmega3sAlready ? <>Continue taking omega-3 supplements</> :<>Take omega-3 supplements</>}</>),
    description: ({omega3Index, supplementOmega3sAlready, isVegetarian, isVegan}) => (
      <p>
        { ((omega3Index >8) && supplementOmega3sAlready) ? <>Maintain your current dosage to help keep your omega-3 index at optimal levels. </>
        
        : <>

        You can find omega-3 fatty acids in foods like fatty fish and flaxseed. But the easiest way to increase your intake
        is taking supplements. 

        <>{omega3Index>6.5 && <> Take at least 1 gram per day with a meal.</>}
        {(omega3Index>4 && omega3Index<=6.5)  && <> Take at least 2 grams per day with a meal.</>}
        {(omega3Index>3 && omega3Index<=4)  && <> Take at least 3 grams per day with a meal.</>}
        {omega3Index <= 3 && <> Take at least 4 grams per day with a meal.</>}</>
        </>
        }
      </p>
    ),
    foodList: ({ isAthlete, isVegan, isVegetarian }) => {
      if (isVegan || isVegetarian) {
        return {
          items: [
            {supplement: ProductCode.OMEGA_3_VEGAN, title: "Supplement"}, 
            {foods:foodData.HIGH_OMEGA3, title:"Foods"}
          ],
          restriction: isVegetarian ? [Diet.VEGETARIAN] :  [Diet.VEGAN]
        }
      } else {
        return {
          items: [
            {supplement: isAthlete ? ProductCode.OMEGA_3_ATHLETE : ProductCode.OMEGA_3, title: "Supplement"}, 
            {foods:foodData.HIGH_OMEGA3, title:"Foods"}
          ]
        }
      }
    },
    products: ({isAthlete}) => isAthlete ? [ProductCode.OMEGA_3_ATHLETE] : [ProductCode.OMEGA_3] ,
    hideProduct: true,
  },   
  {
    code: Recommendation.PRENATAL_VITAMIN,
    kind: RecommendationKind.SUPPLEMENT,
    title: () => <>Take prenatal vitamins</>,
    description: () => <><p>Prenatal vitamins are essential for ensuring you and your baby receive the necessary nutrients for optimal health. They contain critical components like folic acid, iron, vitamin B12, and vitamin D, which are vital for fetal development and maternal health.</p>
    <p>Note: Please consult with your physician before starting any new supplements to ensure they are appropriate for your needs.</p>
    </>,
    products: [ProductCode.PRENATAL_VITAMIN]
  }, 
  { code: Recommendation.CALCIFEDIOL,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({isTakingVitaminD3, isTakingCalcifediol}) => <>
        {
          isTakingVitaminD3 ? 'Continue taking vitamin D supplement'
          : isTakingCalcifediol ? "Continue taking calcifediol"
                              : 'Take vitamin D supplements'}                                                  
      </>,
    description: ({isTakingVitaminD3, isTakingCalcifediol, vitaminDIncreasing, nearOptimalVitaminD, isAthlete}) => 
      <p>
      {isTakingCalcifediol ? <> {(nearOptimalVitaminD || vitaminDIncreasing)? <>Since your vitamin D levels {vitaminDIncreasing ? <>are increasing</>: <>are close to optimal</>}, stick to your current dose and monitor impact on your levels in 3 months.</>
                                                    : <>If you're not consistently taking this daily, continue with current dose and stick with it to see impact. If you are being consistent, consider doubling the dose and assess the impact after 3 months.</>}</>
      : isTakingVitaminD3  ? <> {(vitaminDIncreasing || nearOptimalVitaminD) ? <>Since your vitamin D levels {vitaminDIncreasing ? <>are increasing</>: <>are close to optimal</>}, stick to your current dosage and monitor impact on your levels in 3 months. If you don't reach optimal levels then, consider doubling your current dosage and monitoring impact.</> : <>If you've been consistent with your current dosage and still haven't reached optimal levels, consider doubling your dosage and ensuring you're taking it with a meal to aid absorption.</>}
    
      </>
      : <><p>Start by taking a Vitamin D3 supplement, starting with 5,000 IU daily alongside a meal to aid absorption.</p>
      {/* {isAthlete ? <p>Start by taking a Vitamin D3 supplement, starting with 5,000 IU daily alongside a meal that includes some fat to aid absorption.</p>
      : <p>Calcifediol is a supplement that <Reference url="https://pubmed.ncbi.nlm.nih.gov/29713796/" content="boosts your vitamin D levels 3x quicker"/> than typical vitamin D3 supplements. Start with 10 mcg daily with a meal.</p>} */}
      <p><u>Monitor levels</u>: Check your vitamin D levels in 3 months to assess the effectiveness of supplementation and adjust the dose if needed.</p>
      </>
      }            
      </p>,
    products: ({isAthlete}) => isAthlete ? [ProductCode.VITAMIN_D3_5000_ATHLETE] : [ProductCode.VITAMIN_D3_5000],
  
  },
  { code: Recommendation.VITAMIN_D_MAINTENANCE,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({isTakingVitaminD3, highVitaminD}) => <>{highVitaminD ? 'Reduce vitamin D supplementation' : isTakingVitaminD3 ? 'Continue taking vitamin D supplement': 'Take a vitamin D supplement'}</>,
    description: ({highVitaminD}) => <p>{highVitaminD ? <>To help get back to optimal range, reduce your vitamin D dosage to half your current amount or take the same dosage every other day. Monitor in three months to see where you vitamin D levels stabiize to.</> 
    : <>Now that you've achieved optimal levels, maintain your current dosage and check back in 3 months to make sure you're staying within optimal range.</>}</p>
  },
  { code: Recommendation.OUTDOORS,
    kind: RecommendationKind.LIFESTYLE,
    title: () => 'Spend more time outdoors',
    description: () => <p>Sunlight exposure is the main way your body produces vitamin D, so even a short time outside can make a big difference. On sunny days, consider transforming one of your indoor calls into an outdoor walk-and-talk.</p>,
  },
  { code: Recommendation.LOWER_REFINED_CARBS,
    kind: RecommendationKind.NUTRITION,
    title: () => 'Monitor quality of any carbohydrate intake',
    description: ({lowCarbDiet}) => (
      <>
        <p>Focus on consuming low-glycemic carbohydrates such as leafy greens, berries, and non-starchy vegetables, as they cause a slower and more stable rise in blood glucose levels. Also prioritize high-fiber foods like nuts, seeds, and low-sugar fruits, which slow carb digestion and lead to a gradual glucose release.</p> 
          
        <p>Completely avoid refined carbohydrates such as white bread, pastries, sugary snacks, and sodas, as they cause rapid spikes in blood glucose and insulin levels, contributing to insulin resistance.</p>
        {/* <p>{lowCarbDiet ? 'If you haven\'t already, limit ' : 'Limit '} your intake of refined carbohydrates found in starch and sugar (fructose). Instead, choose high-fiber sources of carbohydrates like leafy greens, nuts, seeds, and fruits that are low in sugar.</p> */}
      </>
    ),
    foodList: () => {
      return {
        items: [
          {foods:foodData.HIGH_FIBER, title:"Eat More"},
          {foods:foodData.HIGH_REFINED_CARBS, title:"Eat Less"}
        ]
      }
    }
  },
  { code: Recommendation.EXERCISE,
    kind: RecommendationKind.EXERCISE,
    title: ({exercises150PerWeek}) => <>{!exercises150PerWeek ? "Exercise at least 3 hours per week" : "Continue to exercise at least 3 hours weekly"}</>,
    description: ({exercises150PerWeek, section}) => 
     <>{section === ReportSection.VAT_ADVICE  ? 
        <p>Continue with regular exercise, combining high-intensity cardio and strength training. Exercise boosts glucose uptake in muscles, helping to regulate blood sugar levels and directly reduce VAT mass.
        </p>
      : <p>Exercise increases glucose uptake in your muscles, allowing your body to use glucose more efficiently for energy. This helps lower blood sugar levels.
      {!exercises150PerWeek && <> Start with short, manageable sessions this week and gradually aim for a total of at least 3 hours each week. <div id="exercise-video" /></>}</p>
    }</>,
  },
  { code: Recommendation.EXERCISE_GENERIC,
    kind: RecommendationKind.EXERCISE,
    title: () => <>Exercise at least 3 hours per week</>,
    description: () => 
      <p>Start with short, manageable sessions this week and gradually aim for a total of at least 3 hours each week.</p>,
  },
  { code: Recommendation.REDUCE_BODY_FAT,
    kind: RecommendationKind.LIFESTYLE,
    title: () => 'Reduce overall body fat',
    description: ({hasMetabolicIssue, hasCardioIssue, section}) => <p>

      {hasMetabolicIssue && (!section || section === ReportSection.METABOLIC_ADVICE) || section===ReportSection.VAT_ADVICE && <p>
        Excess weight, particularly excess abdominal fat, is closely associated with insulin resistance. Losing 5 - 10% of your body weight may{" "}
        <Reference
          content="decrease your risk of developing type 2 diabetes by up to 58%"
          url="https://academic.oup.com/eurheartjsupp/article/7/suppl_L/L21/464317"
        />. 
      </p>}
   
      {hasCardioIssue && (!section || section === ReportSection.CARDIO_ADVICE) && <p>
        Losing 10% of your body weight can {hasMetabolicIssue && <> also </>}{" "}
        <Reference
          content="decrease your risk of a cardiovascular event by 24%"
          url="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5094846/"
        />. 
      </p>}

      <p>It's important to incorporate resistance exercise and ensure adequate protein intake when losing weight. This combination helps to preserve lean muscle mass while maximizing fat loss, particularly from VAT.</p>
      </p>,
  },
  { code: Recommendation.CGM,
    kind: RecommendationKind.TESTING,
    title: () => 'Consider utilizing a CGM',
    description: ({glucoseUp, isolatedHba1c, isMember}) => <p>
    {glucoseUp && <>To understand your rising glucose levels, consider using a CGM (continuous glucose monitor). </>} 
    {isolatedHba1c && <>Occasionally, hbA1c may not accurately reflect average glucose levels due to blood cell size variability. In such cases, using a CGM (continuous glucose monitor) can provide more precise monitoring. </>}
    This minimally-invasive device worn on your arm tracks glucose 24/7 and helps pinpoint the effect of particular food and physicalactivities on your glucose levels.
    </p>,
    products: [ProductCode.LINGO]
  },
  { code: Recommendation.REDUCE_FRUCTOSE,
    kind: RecommendationKind.NUTRITION,
    title: () => 'Reduce fructose intake',
    description: () => <>
    <p>Eliminate drinks with sugars, especially those containing fructose or high fructose corn syrup. And reduce intake of foods containing sugar, especially in processed foods.</p> 
    <p>For fruits, swap out high fructose ones, e.g. grapes and bananas, with low fructose options: kiwi, lime, lemon, blueberries, raspberries, and strawberries. Avoid dried fruits completely.</p> 
    </>,
  },
  { code: Recommendation.DECREASE_SODIUM,
    kind: RecommendationKind.NUTRITION,
    title: () => `Decrease sodium, increase potassium intake`,
    description: ({isVegan, isVegetarian, isTakingBPMedication}) => (
      <>
        <p>Keep daily sodium intake under 1 gram by avoiding added table salt and high-sodium foods like soy sauce and processed items. If you use salt in cooking, use potassium-enriched salt substitutes to lower sodium without sacrificing taste.</p>

        <p>Aim for at least 5 grams of potassium daily from foods like beans, potatoes, spinach, and bananas to naturally raise potassium levels. While supplementation is an option, most offer only 99 mg potassium per tablet so getting all your potassium through supplements will require consuming a large number of pills. It's advisable to first gauge your dietary intake and then supplement to fill in any gaps.</p>
        
        {!isTakingBPMedication && 
        <p>After four weeks of dietary changes, monitor blood pressure daily for a week. Note that dietary changes may not affect everyone's blood pressure significantly. If no improvement, consider non-dietary blood pressure management strategies.</p>}
      </>
    ),
    foodList: ({ isVegetarian, isVegan }) => {
      return {
        items: [
          {foods:foodData.HIGH_POTASSIUM, title:"Eat More"},
          {foods:foodData.HIGH_SODIUM, title:"Eat Less"},
          {supplement: ProductCode.POTASSIUM, title: "Supplement"}, 
        ],
        restriction: isVegetarian ? [Diet.VEGETARIAN] : isVegan ?  [Diet.VEGAN] : []
      }
    },
    studies: [
      {
        url: "https://pubmed.ncbi.nlm.nih.gov/17494929/"
      }
    ]
  },
  { code: Recommendation.INCREASE_POTASSIUM,
    kind: RecommendationKind.NUTRITION,
    title: () => `Decrease sodium, increase potassiun intake`,
    description: () => (
      <>        
        Keep sodium intake to under 1 gram per day by minimizing added salt to meals and avoiding <span id="high-sodium-link" /> like soy sauce and processed foods. 

        <div style={{ paddingTop: 15 }}>
        Boost potassium intake to at least 5 grams daily with <span id="potassium-rich-link" /> like cantaloupe, honeydew melon, orange juice, and bananas.
        </div>
      </>
    ),
    studies: [
      {
        url: "https://pubmed.ncbi.nlm.nih.gov/17494929/"
      }
    ]
  },
  { code: Recommendation.EXERCISE_BP,
    kind: RecommendationKind.EXERCISE,
    title: ({exercises150PerWeek, strengthTrains}) => (<>{(!exercises150PerWeek && !strengthTrains) ? <>Start isometric exercise training</> : <>Incorporate isometric exercise training</>}</>),
    description: ({exercises150PerWeek, strengthTrains, optimalBP, hasAbdominalObesity}) => (
      <>

        {(!exercises150PerWeek && !strengthTrains)  
          ? <><p>Recent research shows  <Reference url="https://bjsm.bmj.com/content/57/20/1317 " content="regular isometric exercise training is the most effective exercise method for lowering blood pressure."/> It can be time-efficient too at just 12-20 minutes per session.</p>
          <p>
          Allocate 3 weekly sessions of <span id="isometric-exercises-link" /> like wall squats and leg extensions. Each session, perform at least four 2 minute sets with 1-3 minute rest intervals.</p></>
          
          : 
          <><p>If you haven't already, incorporate isometric exercise training into your exercise regimen, which recent research shows to be <Reference url="https://bjsm.bmj.com/content/57/20/1317 " content="the most effective exercise method for lowering blood pressure"/>. 
        </p>
        <p>
          Add <span id="isometric-exercises-link" /> like wall squats and leg extensions to three of your weekly exercise sessions. Each session, perform at least four 2 minute sets with 1-3 minute rest intervals.</p> 
          </>
        }
      </>
    ),
  },
  { code: Recommendation.MONITOR_BP,
    kind: RecommendationKind.TESTING,
    title: () => `Monitor your blood pressure`,
    description: ({isTakingBPMedication, hasBP, hasHighCAC}) => (
      <>
        {hasBP ? 
        <p>Some people's blood pressure will rise when taken by a medical professional {isTakingBPMedication ? <>(aka "white coat effect")</> : <>(aka white coat hypertension)</>}. Get a  <a href="https://www.validatebp.org/" target="_blank">validated blood pressure monitor</a> to verify your levels and track if it is improving over time. Sit in a rested state to
        ensure comparable readings.</p> :
        <>
          <p>Monitoring your blood pressure is key because elevated levels can significantly increase your risk of heart attack, stroke, and other cardiovascular events{hasHighCAC && <>—particularly when combined with a high CAC score</>}.</p>
          <p>Because high blood pressure often has no symptoms, consistent tracking allows for early detection and effective management, helping to lower your risk.</p>
          <p>Aim for a blood pressure goal of 120/80 mmHg or lower, unless otherwise advised by your physician.</p>
        </>}
      </> 
    ),
    products: [ProductCode.BP_MONITOR],
  },
  { code: Recommendation.VERIFY_BP,
    kind: RecommendationKind.TESTING,
    title: () => `Verify your average blood pressure`,
    description: () => <p>Consider buying a <a href="https://www.validatebp.org/" target="_blank">validated blood pressure monitor</a> to verify if your blood pressure is consistently abnormal. Remember to sit in a calm and rested state to ensure accurate readings.</p>,
    products: [ProductCode.BP_MONITOR]
  },
  { code: Recommendation.REDUCE_METHIONINE,
    kind: RecommendationKind.NUTRITION,
    title: () => "Reduce methionine-rich foods",
    description: ({ diet }) => (
      <>
      <p>
        {diet?.includes(Diet.VEGETARIAN)
        
          ? <>Methionine is the precursor to homocysteine and is found in almost all protein-containing foods. Vegetarian foods rich in methionine include eggs and dairy yogurt. Most vegetables and fruits, except for a few like Brazil nuts, are low in methionine.</>
        
          : <>Methionine is the precursor to homocysteine and is found in almost all protein-containing foods. Foods rich in methionine include {!diet?.includes(Diet.PESCATARIAN) && <>red meat,</>} seafood and eggs. Most vegetables and fruits, except for a few like Brazil nuts, are low in methionine. Reducing animal protein and replacing it with plant-based sources can help lower your homocysteine levels.</>
        }
      </p>
      </>
    ), 
    foodList: ({ diet }) => {
      return {
        items: [{foods:foodData.HIGH_METHIONINE,title: ">300 mg methionine / serving"}],
        restriction: diet
      }
    }
  },
  { code: Recommendation.B12_FOODS,
    kind: RecommendationKind.NUTRITION,
    title: () => "Eat more foods high in vitamin B12",
    description: ({shouldNotEatSaturatedFats}) => (
      <>
        <p>
        Vitamin B12 can be sourced from your diet. It is present in many animal products, including meats, dairy and eggs. The highest concentrations are in
        clams and liver.{shouldNotEatSaturatedFats && <>{" "}However, be mindful of your intake of B12-rich foods that are also rich in saturated fats.</>}
        </p>
      </>
    ),
    foodList: () => {
      return {
        items: [{foods:foodData.HIGH_VITAMIN_B12, title: "Eat More"}]
      }
    },
  },
  { code: Recommendation.B9_FOODS,
    kind: RecommendationKind.NUTRITION,
    title: () => "Eat foods high in vitamin B9",
    description: () => (
      <>
        <p>Vitamin B9 can be primarily sourced from your diet. It is present in many plant-based products, including legumes and darky leafy greens.</p> 
      </>
    ),
    foodList: () => {
      return {
        items: [{foods:foodData.HIGH_VITAMIN_B9, title: "Eat More"}]
      }
    }
  },
  { code: Recommendation.VITAMIN_B9_MAINTENANCE,
    kind: RecommendationKind.SUPPLEMENT,
    title: () => 'Continue vitamin B9 supplement',
    description: () => (
      <p>Now that you've achieved optimal levels, maintain your current dosage and check back in 3 months to make sure you're staying within optimal range.</p>
    ),
  },
  { code: Recommendation.VITAMIN_B12_MAINTENANCE,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({supplementB12Already}) => <>{supplementB12Already ? 'Continue vitamin B12 supplement': 'Take a vitamin B12 supplement'}</>,
    description: ({highHomocysteine}) => (
      <p>
      {highHomocysteine 
        ? <>When your vitamin B12 reaches optimal levels, you usually can lower your dosage to half. However, with <span className="view-test" id={`view-test-${TestCode.HOMOCYSTEINE}`} text="high homocysteine" /> levels, it's recommended to continue your current B12 dosage, as it aids in lowering homocysteine.</>
        : <>Now that you've achieved optimal levels, maintain your current dosage and check back in 3 months to make sure you're staying within optimal range.</>
      }
      </p>
    ),
  },
  { code: Recommendation.VITAMIN_B12_B9_MAINTENANCE,
    kind: RecommendationKind.SUPPLEMENT,
    title: () => 'Continue vitamin B9 + B12supplement',
    description: () => (
      <p>Now that you've achieved optimal levels, maintain your current dosage and check back in 3 months to make sure you're staying within optimal range.</p>
    ),
  },
  { code: Recommendation.B12_SUPPLEMENT,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({supplementB12Already}) => <>{supplementB12Already ? "Continue vitamin B12 supplements" : "Take vitamin B12 supplements"}</>,
    description: ({supplementB12Already, nearOptimalVitaminB12}) => (
      <p>
        {supplementB12Already 
          ? <>
          If you're regularly taking your supplements, try doubling the dose and assess changes after three months. If you've been inconsistent, simply resume regular intake to reach optimal levels quickly.</> 
          : nearOptimalVitaminB12 ? <>Take 1000 mcg of methylcobalamin, the most bioavailable forms of vitamin B12, daily as a chewable sublingual or liquid supplement to quickly boost your levels to an optimal level in weeks.</> : <>Take 5000 mcg of methylcobalamin, the most bioavailable forms of vitamin B12, daily as a chewable sublingual or liquid supplement to quickly boost your levels to an optimal level in weeks.</>
        }
      </p>

    ),
    products: ({nearOptimalVitaminB12}) => nearOptimalVitaminB12 ?  [ProductCode.VITAMIN_B12_1000] : [ProductCode.VITAMIN_B12]
  },
  { code: Recommendation.STRENGTH_TRAIN,
    kind: RecommendationKind.EXERCISE,
    title: ({strengthTrains}) => <>{strengthTrains ?  `Continue strength training` : `Start strength training`}</>,
    description: ({strengthTrains}) => 
      <p>{strengthTrains
        ? <>Continue with your current routine while closely monitoring your grip strength to ensure progress. Progressive overload is crucial for muscle development, so if it's not already part of your regimen, start gradually increasing the weight or resistance in your exercises.</>
        : <>Focus on exercises like squats, bench presses, and deadlifts. These workouts will build overall muscle strength and also enhance grip strength.</>
        }</p>
  },
  { code: Recommendation.MONITOR_GRIP,
    kind: RecommendationKind.TESTING,
    title:() => `Monitor your grip strength`,
    description:() => <p>Grip strength is one indicator of overall muscle health and very easy to monitor. If you love real-time data, get a dynamometer to track if your grip strength is improving over time.</p>,
    products: [ProductCode.DYNAMOMETER]
  },
  { code: Recommendation.NICOTINE_REPLACEMENTS,
    kind: RecommendationKind.MEDICATION,
    title:() =>  `Consider medications or nicotine replacements`,
    description: () => <p>Talk to your primary care provider about prescribed medications like varenicline and bupropion and/or nicotine replacements like patches combined with gum or lozenges.</p>,
  },
  { code: Recommendation.SMOKING_CBT,
    kind: RecommendationKind.LIFESTYLE,
    title:() =>  'Consider cognitive behavioral therapy (CBT)',
    description:() => <p>CBT for smoking cessation involves changing thoughts and behaviors related to smoking, developing coping strategies, and preventing relapse, all tailored to your needs. Combining CBT with pharmacotherapy is a very effective way to help you quit smoking successfully.</p>,
    products: [ProductCode.PELAGO]
  },
  { code: Recommendation.IRON,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({isTakingIron}) => <>{isTakingIron ? `Continue iron supplements` : `Take iron supplements`}</>,
    description: ({isAnemic, isTakingIron}) =>
      <>
      {isTakingIron && !isAnemic && <><p>If you're regularly taking your supplements, try doubling the dose and assess changes after three months. If you've been inconsistent, simply resume regular intake to reach optimal levels quickly.</p><p>
        <u>Monitor levels</u>: Test your ferritin in 3 months to assess the effectiveness of supplementation and adjust the dose if needed. Balancing iron is crucial—too little or too much can be bad.</p></>}

      {(!isTakingIron || isAnemic) && <p>Take {isAnemic ? <>120 mg</> : <>50 mg</>} of elemental iron every other day, ideally on empty stomach or a source of vitamin C (e.g. orange juice) to maximize absorption. Avoid combining iron with calcium, antacids, high-fiber foods, or caffeine, as these can interfere with iron absorption.
      <br/><br/>
      <u>Side effects</u>: Iron supplementation can cause nausea, constipation, or stomach upset. Discuss with a physician before starting. If side effects occur, stop and consult your physician.
      {!isAnemic && <p>
      <u>Monitor levels</u>: Test your ferritin in 3 months to assess the effectiveness of supplementation and adjust the dose if needed. Balancing iron is crucial—too little or too much can be bad.</p>}</p>}
      
      </>,
    products: [ProductCode.IRON],
  },
  { code: Recommendation.IRON_SUPPLEMENT_MAINTENANCE,
    kind: RecommendationKind.SUPPLEMENT,
    title: () => 'Continue iron supplement',
    description: () => (
      <p>Now that you've achieved optimal levels, maintain your current dosage and check back in 3 months to make sure you're staying within optimal range.</p>
    ),
  },
  { code: Recommendation.STATIN,
    kind: RecommendationKind.MEDICATION,
    title: ({statinAlready}) => <>{statinAlready ? `Continue cholesterol-lowering medication` : `Consider cholesterol-lowering medication`}</>,
    description: ({statinAlready}) => (
      <p>{statinAlready 
          ? `Since your physician has prescribed cholesterol-lowering medication, we recommend continuing quarterly testing to confirm it's effectively helping you reach your ApoB and LDL goals.` 
          :  `Given your current cardiovascular disease risk, medication is advisable. Ezetimibe and statins are commonly prescribed first-line treatments to reduce your ApoB and LDL cholesterol levels, significantly cutting your risk for heart attack and stroke. Their efficacy and safety are well-supported by extensive outcome trials.`}
      </p>
    ),
    products: ({isHeartHealthMember}) => isHeartHealthMember ? [ProductCode.HEART_HEALTH_CONSULT] : [ProductCode.HEART_HEALTH_MEMBERSHIP] },
  { code: Recommendation.CHOLESTEROL_MEDICATION_MAYBE,
    kind: RecommendationKind.MEDICATION,
    title: () => <>Consider cholesterol-lowering medication</>,
    description: () => 
      <p>If diet changes and supplementation don't lower your apoB and LDL cholesterol to optimal levels, it's wise to consider cholesterol-lowering medications like ezetimibe and statins. Backed by substantial clinical evidence, these medications effectively reduce levels and significantly lower cardiovascular disease risk.
      </p>,
    products: ({isHeartHealthMember}) => !isHeartHealthMember && [ProductCode.HEART_HEALTH_MEMBERSHIP] 
  },
  { code: Recommendation.EZETIMIBE,
    kind: RecommendationKind.MEDICATION,
    title: () => <>{`Talk to your physician about ezetimibe`}</>,
    description: () => 
      <p>Ezetimibe is particularly beneficial for people like you who overabsorb cholesterol ( <span className="view-test" id={`view-test-${TestCode.CAMPESTEROL}`} text="high camposterol" /> and <span className="view-test" id={`view-test-${TestCode.SITOSTEROL}`} text="high sitosterol" />) due to its unique mechanism of action. Unlike statins, which primarily work by inhibiting cholesterol synthesis in the liver, ezetimibe targets the cholesterol absorption process in the intestine.</p>,
    products: ({isHeartHealthMember}) => !isHeartHealthMember && [ProductCode.HEART_HEALTH_MEMBERSHIP] 
  },
  { code: Recommendation.HEART_HEALTH_TEST,
    kind: RecommendationKind.TESTING,
    title: () => <>Get heart health blood test done</>,
    description: () => <p>Pair your coronary calcium results with key blood biomarkers such as apoB, Lp(a), hs-CRP, HbA1c, and more for a complete understanding of your heart health. This comprehensive approach helps identify and manage all potential heart disease risk factors.</p>,
    products: ({isHeartHealthMember}) => !isHeartHealthMember && [ProductCode.HEART_HEALTH_TEST] 
  },
  { code: Recommendation.MONITOR_HEART_HEALTH_TEST,
    kind: RecommendationKind.TESTING,
    title: () => <>Monitor key heart health blood biomarkers</>,
    description: () => <p>Regularly tracking critical blood biomarkers provides a comprehensive view of your cardiovascular health. Testing markers such as ApoB, Lp(a), hs-CRP, and HbA1c at least once a year can reveal early signs of inflammation, cholesterol imbalances, and blood sugar issues. By detecting these changes early, we can implement timely interventions to prevent the progression of heart disease, helping you stay on top of your heart health.</p>,
    products: ({isHeartHealthMember}) => !isHeartHealthMember && [ProductCode.HEART_HEALTH_TEST] 
  },

  { code: Recommendation.GLUCOSE_LOWERING_MEDICATION,
    kind: RecommendationKind.MEDICATION,
    title: ({takingGlucoseMedicationAlready, isTakingMetformin}) => <>{!takingGlucoseMedicationAlready ? `Consider taking metformin`  : isTakingMetformin ? `Continue taking metformin`  : `Continue glucose-lowering medication`}</>,
    description: ({takingGlucoseMedicationAlready, isTakingMetformin, isMember}) => 
      <p>{takingGlucoseMedicationAlready ? <>Since your physician has already prescribed you {isTakingMetformin ? `metformin` : `a glucose-lowering medication`}, it's reasonable to continue testing every 3 months to ensure that the medication is indeed helping you achieve your metabolic goals and lower your risk for diabetes.</>
      :<>Metformin is a medication commonly used to lower blood sugar levels and improving your body's sensitivity to insulin. It is usually well-tolerated.</>}
      </p>,
    products: ({isHeartHealthMember}) => !isHeartHealthMember && [ProductCode.HEART_HEALTH_MEMBERSHIP] 
  },
  { code: Recommendation.GLUCOSE_LOWERING_MEDICATION_ADJUST,
    kind: RecommendationKind.PROVIDER,
    title: () => <>{`Talk to your physician about your glucose-lowering medication`}</>,
    description: () => 
      <p>Your glucose levels are not improving so it's worth discussing with the physician that prescribed your glucose-lowering medication about the reason for this. It's possible an adjustment needs to be made.</p>,
  },
  { code: Recommendation.TALK_TO_PCP_DIABETES,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to your physician about diabetes`,
    description: () => 
      <p>Lifestyle changes including diet and exercise are absolutely necessary. It's recommended you also consult your primary care provider to discuss other treatment options and how to carefully manage this condition.</p>,
  },
  { code: Recommendation.TALK_TO_PCP_TESTOSTERONE,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to your physician about testosteorne deficiency`,
    description: () => 
      <p>Lifestyle changes to control your metabolic issues, including diet and exercise, are absolutely necessary to help increase your testosterone. It's advised you also consult your primary care provider to discuss other treatment options and how to carefully manage this condition.</p>,
  },
  { code: Recommendation.TALK_TO_PCP_BP,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to your physician about hypertension`,
    description: () => 
      <>
        <p>Your blood pressure readings indicate potential hypertension. If untreated, this raises your risk for heart disease, heart failure, and stroke by 2-4 times.</p>
        <p>If you've followed prior recommendations (reducing sodium, increasing potassium, exercising), please see your primary care provider to verify and discuss management strategies.</p>
      </>,
  },
  { code: Recommendation.B9_B12_SUPPLEMENT,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({supplementB9Already, supplementB12Already}) => <>{(supplementB9Already && supplementB12Already) ? "Continue vitamin B9 + B12 supplement" : "Take vitamin B9 + B12 supplement"}</>,
    description: ({supplementB9Already, supplementB12Already, nearOptimalVitaminB12, nearOptimalVitaminB9, isAthlete}) => (
      <p>
        {(supplementB9Already && supplementB12Already) 
          ? <>Since you're taking these supplements already, consider doubling your current dosage and monitoring the impact in 3 months.</> 
          : isAthlete ? <>Take 1333 mcg of methylfolate and 800 mcg of methylcobalamin, the most bioavailable forms of vitamins B9 and B12, daily.</> 
          : nearOptimalVitaminB12 && nearOptimalVitaminB9 ? <>Take 400 mcg of methylfolate and 1000 mcg of methylcobalamin, the most bioavailable forms of vitamins B9 and B12, daily as a chewable sublingual or liquid supplement.</>
          : <>Take 800 mcg of methylfolate and 5000 mcg of methylcobalamin, the most bioavailable forms of vitamins B9 and B12, daily as a chewable sublingual or liquid supplement.</>
        }
      </p>
    ),
    products: ({nearOptimalVitaminB12, nearOptimalVitaminB9, isAthlete}) => isAthlete ? [ProductCode.VITAMIN_B9_1333_B12_800_ATHLETE] : nearOptimalVitaminB12 && nearOptimalVitaminB9 ? [ProductCode.VITAMIN_B9_400_B12_1000 ] : [ProductCode.VITAMIN_B9_800_B12_5000 ]
  },
  { code: Recommendation.B9_SUPPLEMENT,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({supplementB9Already}) => <>{supplementB9Already ? "Continue vitamin B9 supplement" : "Take vitamin B9 supplement"}</>,
    description: ({supplementB9Already, lowVitaminB9}) => (
      <p>
        {supplementB9Already
          ? <>Since you're taking these supplements already, considering doubling your current dosage and monitoring the impact in 3 months.</> 
          : <>Take at least 1000 mcg of methylfolate, the most bioavailable forms of vitamins B9, daily with food.</>
        }
      </p>
    ),
    products: ({isAthlete}) => isAthlete ? [ProductCode.VITAMIN_B9_1333_B12_800_ATHLETE] : [ProductCode.VITAMIN_B9]
  },  
  { code: Recommendation.HOMOCYSTEINE_B9_B12_SUPPLEMENT,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({supplementB12Already, supplementB9Already}) => <>{(supplementB12Already && supplementB9Already) ? "Continue vitamin B9 + B12 supplement" : "Take vitamin B9 + B12 supplement"}</>,
    description: ({supplementB12Already, supplementB9Already, lowVitaminB12, lowVitaminB9, hasMacrocytosis, isMeatEater, diet, nearOptimalVitaminB12, nearOptimalVitaminB9, isAthlete}) => (
      <>
      <p>
        Boosting your vitamin B9 and B12 helps convert homocysteine into other products, resulting in lower homocysteine levels.
        {lowVitaminB12 && lowVitaminB9 && (
          <>
            {" "}This will also help increase your <span className="view-test" id={`view-test-${TestCode.FOLATE}`} text="low vitamin B9" /> and <span className="view-test" id={`view-test-${TestCode.VITAMIN_B12}`} text="low vitamin B12" />
            {!isMeatEater && <>, which may be due to your {diet.some(item => item === Diet.VEGETARIAN) ? <>vegetarian</> : <>vegan</>} diet, </>} to optimal levels
            {hasMacrocytosis && <> and bring down your <span className="view-test" id={`view-test-${TestCode.MCV}`} text="MCV" />, which is likely high due to vitamin B deficiencies</>}.
          </>
        )}</p>

        {(supplementB12Already && supplementB9Already) 
          ? <p>Since you're already taking these supplements, considering doubling your current dosage and monitoring impact on your levels in 3 months.</p>
          : isAthlete ? <p>Take 1333 mcg of methylfolate and 800 mcg of methylcobalamin, the most bioavailable forms of vitamins B9 and B12, daily.</p> : nearOptimalVitaminB12 && nearOptimalVitaminB9 ? <p>Take 400 mcg of methylfolate and 1000 mcg of methylcobalamin, the most bioavailable forms of vitamins B9 and B12, daily as a chewable sublingual or liquid supplement.</p> : <p>Take 800 mcg of methylfolate and 5000 mcg of methylcobalamin, the most bioavailable forms of vitamins B9 and B12, daily as a chewable sublingual or liquid supplement.</p>
        }
        <p><u>Monitor levels</u>: It's important to check your levels again in 3 months to assess the effectiveness of your supplementation and adjusted as needed.</p>
      </>
    ),
    products: ({nearOptimalVitaminB12, nearOptimalVitaminB9, isAthlete}) => isAthlete ? [ProductCode.VITAMIN_B9_1333_B12_800_ATHLETE] : nearOptimalVitaminB12 && nearOptimalVitaminB9 ? [ProductCode.VITAMIN_B9_400_B12_1000 ] : [ProductCode.VITAMIN_B9_800_B12_5000 ]
  },
  { code: Recommendation.IRON_FOODS,
    kind: RecommendationKind.NUTRITION,
    title: () => "Eat more iron-rich foods",
    description: ({isMeatEater}) => (
      <p>
        {isMeatEater 
          ? <>Dietary iron is available as heme iron in animal products like beef, poultry, and seafood, and as non-heme iron in plant-based foods such as beans, tofu, seeds, and fortified cereals. Heme iron is more easily absorbed than non-heme iron. Aim to get at least 14 mg of iron per day from your diet.</>
          : <>For vegans and vegetarians, good sources tofu, dark leafy greens (like broccoli, spinach and kale), nuts, seeds, and dried fruits (like apricots and raisins). Aim to get at least 14 mg of iron per day from your diet.</>
        }
      </p>
    )
  },
  { code: Recommendation.HIGH_BLOOD_PRESSURE_MEDICATION,
    kind: RecommendationKind.MEDICATION,
    title: () => <>Continue high blood pressure medication</>,
    description: () => 
      <>
        <p>
        Since your physician has already prescribed you a blood pressure lowering medication, continue taking them and monitor your levels to confirm it is indeed helping you achieve your blood pressure goals. 
        </p>
        <p>
        If you haven't already, get a  <a href="https://www.validatebp.org/" target="_blank">validated blood pressure monitor</a>  to do this. Sit in a rested state to ensure comparable readings.</p>
      </>,
    products: [ProductCode.BP_MONITOR]
  },
  { code: Recommendation.TALK_TO_PCP_FH,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to your physician about FH`,
    description: ({familyHistoryHighCholesterol}) => 
      <>
      <p>Your very high ApoB and LDL cholesterol{familyHistoryHighCholesterol && `, coupled with family history of high cholesterol,`} raise suspicion of possible Familial Hypercholesterolemia (FH), a genetic condition that disrupts cholesterol metabolism.</p> 
      <p>
      Without proper management, it greatly increases your risk of coronary artery disease due prolonged exposure to elevated ApoB and LDL levels. It's important to discuss your results with your primary care provider or a lipidologist for diagnosis and best management strategies.
      </p>
      </>,
      products: [ProductCode.HEART_HEALTH_CONSULT]

  },
  { code: Recommendation.TALK_TO_PCP_SEVERETG,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to your physician about severe hypertriglyceridemia`,
    description: () => 
      <p>Lifestyle changes are absolutely necessary. It's recommended you also consult your primary care provider to discuss additional treatment options and how to carefully manage this condition.</p>,
  },
  { code: Recommendation.LOW_FAT_DIET,
    kind: RecommendationKind.NUTRITION,
    title: ({lowFatDietAlready}) => lowFatDietAlready ? `Continue low-fat diet` : `Start low-fat diet`,
    description: () => (
      <>
      <p>This diet is specifically designed to reduce the production of lipoprotein particles called chylomicrons, which transport your triglycerides from the intestines to various tissues.</p>
      <p>Limit fat intake to less than 15% of total calories per day. Cut down on saturated fats, unsaturated fats AND refined carbohydrates. When your triglyceride levels fall below 500 mg/dL, you can gradually increase unsaturated fats.</p>
      </>
    ),
    foodList: () => {
      return {
        items: [
          {foods: foodData.HIGH_SATURATED_FATS, title: "Avoid"},
          {foods: foodData.HIGH_UNSATURATED_FATS, title: "Eat Less"},
          {foods: foodData.HIGH_REFINED_CARBS, title: "Eat Less"},
        ]
      }
    }
  },
  { code: Recommendation.THYROID_MEDICATION,
    kind: RecommendationKind.MEDICATION,
    title: () => <>Continue thyroid medication</>,
    description: () => 
      <p>
        Since your physician has already prescribed you medication to manage your thyroid condition, continue taking it and monitor your levels to confirm it is indeed helping you achieve your thryoid goals. 
      </p>,
  },
  { code: Recommendation.TALK_TO_PCP_ANEMIA,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to your physician about anemia`,
    description: ({isAnemiaBetter}) => 
      <p>{isAnemiaBetter ? <>While your condition is improving, it may still be worth discussing with your primary care provider about what may causing your body to not produce enough hemoglobin and appropriate next steps.</> 
                  : <>Your primary care provider or hematologist, a doctor who specializes in blood disorders, can help determine what may causing your body to not produce enough hemoglobin and appropriate next steps.</>
      }</p>,
      referralType: () => ReferralType.HEMATOLOGIST
  },
  { code: Recommendation.TALK_TO_PCP_CKD,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to your physician about kidney dysfunction`,
    description: () => 
      <p>Your primary care provider or nephrologist, a doctor who specializes in kidney disorders, can help determine what may causing low eGFR levels and best management strategy.</p>,
  },
  { code: Recommendation.WATER_INTAKE,
    kind: RecommendationKind.NUTRITION,
    title: () => "Ensure proper daily water intake",
    description: () => 
      <>
      <p>One good measure of proper hydration is your urine color. Aim for a pale straw-yellow. Darker urine usually indicates a need for more water.</p>
      <p>
      If plain water isn't appealing, incorporate water-rich foods into your diet, such as cucumbers, watermelon, and tomatoes. Alternatively, opt for calorie-free flavored seltzers, or make your water more interesting by adding low-calorie flavors like citrus zest.</p></>
  },
  { code: Recommendation.THYROID_PANEL,
    kind: RecommendationKind.TESTING,
    title: ({didFullThyroidPanel}) => didFullThyroidPanel ? `Monitor thyroid function` : `Get comprehensive thyroid panel done`,
    description: ({isMember, didFullThyroidPanel}) => 
      <>
      <p>Schedule a follow-up thyroid panel within 3 months to track any changes in TSH, free T3, and free T4 levels. Monitoring these levels over time will help determine whether the condition is progressing or stabilizing.
      </p>
      <EmailConcierge subject="Comprehensive+Thyroid+Panel" isMember={isMember}/>
      </>,
  },
  
  { code: Recommendation.CKD_FIX_METABOLIC_SYNDROME,
    kind: RecommendationKind.LIFESTYLE,
    title: () => <>Double down on improving your blood pressure and metabolic health</>,
    description: () => 
      <p>High blood pressure is a key risk factor for kidney damage. Moreover, effectively managing your glucose levels and maintaining a healthy weight are crucial to preventing or slowing the progression of kidney disease.</p>
  },
  { code: Recommendation.TALK_TO_PCP_HORMONES,
    kind: RecommendationKind.PROVIDER,
    title: () => <>Talk to your physician about hormonal imbalances</>,
    description: () => 
      <p>Your primary care provider or an endocrinologist, an expert in hormonal health, can help determine what is causing your abnormal hormone levels and appropriate next steps.</p>
  },
  { code: Recommendation.TALK_TO_PCP_LIVER,
    kind: RecommendationKind.PROVIDER,
    title: () => <>Talk to your physician about possible liver issue</>,
    description: () => <p>Given repeat testing shows this is likely not transient, your physician can help determine the underlying issue and treatment strategy if needed.</p>
  },
  { code: Recommendation.REPEAT_SEX_HORMONES_TESTS,
    kind: RecommendationKind.TESTING,
    title: () => <>Repeat sex hormones tests</>,
    description: ({isMember}) => 
        <>
        <p>Consider retesting within 3 months, ideally between 8-10am when testosterone naturally peaks, to see if this issue is temporary or ongoing. If they persist, consult your primary care provider or an endocrinologist to help determine the underlying issue and best next steps.</p>
        <EmailConcierge subject="Sex+Hormone+Tests" isMember={isMember}/>
        </>,
  },
  { code: Recommendation.TG_LOWERING_MEDICATION,
    kind: RecommendationKind.MEDICATION,
    title: ({takingTGMedicationAlready}) => <>{!takingTGMedicationAlready ? `Talk to your physician about trigylceride-lowering medication`  : `Continue triglyceride-lowering medication`}</>,
    description: ({takingTGMedicationAlready}) => 
      <p>Since your physician has already prescribed you a trigylceride-lowering medication, it's reasonable to continue testing every 3 months to ensure that the medication is indeed helping you achieve your triglyceride goals.</p>,
    products: [ProductCode.HEART_HEALTH_MEMBERSHIP]
  },
  { code:Recommendation.MONITOR_LOW_FERRITIN,
    kind: RecommendationKind.TESTING,
    title: () => <>Monitor ferritin levels</>,
    description: ({isMember}) => 
      <>
        <p>Retest for ferritin after 6 weeks of iron supplementation to confirm if diet is the likely issue; if no significant increase, consult your physician to determine underlying cause.</p>
        <EmailConcierge subject="Ferritin" isMember={isMember}/>
      </>,
  },
  { code:Recommendation.MONITOR_ANEMIA,
    kind: RecommendationKind.TESTING,
    title: () => <>Monitor ferritin and red blood cell levels</>,
    description: ({isMember}) => 
      <>
        <p>It's important to keep on top of this condition to make sure it's improving and not worsening. Check for ferritin, hemoglobin, hematocrit levels in 3 months, especially if you start iron supplementation so you can confirm the effectiveness of supplementation and adjust the dose if needed.</p>
        <EmailConcierge subject="Ferritin" isMember={isMember}/>
      </>,
  },
  { code:Recommendation.REPEAT_FERRITIN,
    kind: RecommendationKind.TESTING,
    title: () => <>Repeat ferritin test</>,
    description: ({isMember}) => 
      <>
        <p>Consider retesting your ferritin and other iron biomarkers (Total Iron, TIBC, Transferrin Saturation) within 3 months to determine if this issue is transient or persistent. If the latter, you should consult your primary care provider to help determine the underlying issue and appropriate next steps.</p> 
        <EmailConcierge subject="Ferritin" isMember={isMember}/>
      </>,
  },
  { code: Recommendation.TALK_TO_PCP_LOW_WBC,
    kind: RecommendationKind.PROVIDER,
    title: () => <>Talk to physician about your low WBC count</>,
    description: () => <p>Given repeat testing shows this is likely not transient, your physician can help determine the underlying issue and treatment strategy if needed.</p>
  },
  { code: Recommendation.TALK_TO_PCP_THYROID_MEDICATION,
    kind: RecommendationKind.PROVIDER,
    title: () => <>Talk to your physician about your thyroid medication</>,
    description: ({isMember}) => <>
      <p>Consider retesting your TSH to determine if the elevation is temporary or persistent. And/or discuss your elevated TSH levels and potential medication adjustments with the doctor who is managing your thyroid treatment.</p>
      <EmailConcierge subject="Comprehensive+Thyroid+Panel" isMember={isMember}/>    
    </>
  },
  { code: Recommendation.FECAL_TEST,
    kind: RecommendationKind.TESTING,
    title: () => <>Get fecal globin test</>,
    description: () => 
      <p>
        A fecal globin test, also known as a fecal immunochemical test (FIT), detects hidden (occult) blood in the stool, which can be a sign of lower gastrointestinal disorders such as colorectal cancer, polyps, or other conditions. Positive results typically warrant follow-up with a colonoscopy for further evaluation.
      </p>,
    products: [ProductCode.FIT]
  },
  { code: Recommendation.REDUCE_SUPPLEMENTATION,
    kind: RecommendationKind.SUPPLEMENT,
    title: ({target}) => <>Reduce {target} supplementation</>,
    description: ({target}) => 
      <p>  
        Reduce your current supplementation by 50% and monitor to see if it gets you back to optimal levels. 
      </p>
  },
  { code:Recommendation.REPEAT_HSCRP,
    kind: RecommendationKind.TESTING,
    title: () => <>Repeat inflammation tests</>,
    description: ({isMember}) => 
      <>
        <p>Consider retesting within 1 month to determine if this issue is transient or persistent. If the latter, you should consult your physician to help determine the underlying issue and appropriate next steps.</p> 
        <EmailConcierge subject="hs-CRP" isMember={isMember}/>
      </>,
  },
  { code: Recommendation.TALK_TO_HEPATOLOGIST,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to hepatologist about potential liver issue`,
    description: () => 
      <p>
        Discuss your elevated liver enzyme levels with a hepatologist. They will determine the underlying issue and best management strategy moving forward.
      <div className="contact-concierge">
      Email <a href="mailto:concierge@instalab.com?Subject=FH">concierge@instalab.com</a> if you need assistance finding a physician to help you with this. 
      </div>
      </p>,
  },
  {
    code: Recommendation.CAC,
    kind: RecommendationKind.TESTING,
    title: () => "Get coronary calcium scan done",
    description: () => <>
      <p>
        This non-invasive CT scan of the heart measures the amount of calcium in the coronary artery walls, providing a clearer picture of heart health. 
      </p>
      <p> 
      While cholesterol levels are often discussed, they don't tell the whole story. Atherosclerosis progresses over time, and this scan helps detect calcified plaque in the arteries, giving a more comprehensive assessment of how advanced the condition may be. The results can guide how aggressive treatment should be to lower the risk of coronary artery disease.
    </p></>,
    products: [ProductCode.CAC]
  },
  {
    code: Recommendation.MONITOR_CAC,
    kind: RecommendationKind.TESTING,
    title: () => "Schedule follow-up coronary calcium scan in three years",
    description: () => <>
      <p>
      Regular monitoring of your CAC score is essential for proactively managing your cardiovascular health. By getting a follow-up scan every 3 years, we can detect any changes in arterial plaque buildup early, allowing for timely adjustments to your treatment plan. This proactive approach helps prevent the progression of heart disease and ensures that you're maintaining optimal heart health. 
      </p>
      </>,
    products: [ProductCode.CAC]
  },
  {
    code: Recommendation.CCTA,
    kind: RecommendationKind.TESTING,
    title: () => "Get CCTA to further stratify your risk",
    description: () => <><p>
       A CCTA (Coronary CT Angiogram) is a non-invasive scan that provides a detailed assessment of your coronary arteries, detecting blockages and different types of plaques, including calcified and soft plaques. This is crucial because soft plaques are more prone to rupture, which can significantly increase the risk of heart attacks. By assessing the extent and type of plaque buildup, a CCTA allows for more tailored treatment strategies to lower your risk of heart disease.
       <p>This is different from a coronary calcium scan, which focuses solely on detecting calcified plaques without identifying soft plaques or assessing blockages. Although CCTA is more costly, it provides a fuller picture of your heart health, enabling more precise treatment decisions.</p>
       <p>For proactive heart care, getting a CCTA as an early baseline is beneficial. If results show no issues, retesting every 5 years can help detect changes, allowing timely interventions to maintain optimal heart health.</p>
    </p></>,
    products: [ProductCode.CCTA]
  },

  { code: Recommendation.TALK_TO_PCP_HYPONATREMIA,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to your physician about hyponatremia`,
    description: () => 
    <>      
      <p>
        Treatment for hyponatremia depends on the underlying cause but may include fluid restriction, medication, or intravenous saline solutions in more severe cases. It's crucial to diagnose and treat hyponatremia early to prevent complications.
      </p>
      <p>
      Discuss your low sodium levels with your physician. They will determine whether this is something you should focus on, the underlying issue and best management strategy. 
      </p>
    </>,
  },

  { code: Recommendation.TMG_SUPPLEMENT,
    kind: RecommendationKind.SUPPLEMENT,
    title: () => <>Take TMG supplement</>,
    description: () => 
      <>
        <p>TMG (trimethylglycine) decreases homocysteine levels by donating one of its methyl groups to homocysteine and thereby converting it into other products. Take 4-6g daily.</p> 

      </>,
    products: [ProductCode.TMG]
  },
  { code: Recommendation.MTFHR_TEST,
    kind: RecommendationKind.TESTING,
    title: () => <>Consider MTHFR testing</>,
    description: () => 
      <>
        <p>High homocysteine levels that persist despite optimal vitamin B levels could suggest a mutation in the MTHFR (methylenetetrahydrofolate reductase) gene. This genetic variation can lead to inefficiencies in how the body processes and metabolizes folate, a crucial vitamin for DNA synthesis, repair, and methylation, a process essential for gene expression and homocysteine regulation.</p> 
        <div className="contact-concierge">
        Email <a href="mailto:concierge@instalab.com?Subject=MTFHR">concierge@instalab.com</a> if you need assistance in getting this test done. 
      </div>
      </>
  },
  {
    code: Recommendation.IMPROVE_SLEEP,
    kind: RecommendationKind.LIFESTYLE,
    title: () => 'Improve sleep quality',
    description: () => 
      <>
        <p><Reference url='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9036496/' content='Poor sleep is closely linked to insulin resistance'/>. Ensuring 7-9 hours of quality sleep per night can significantly boost insulin sensitivity. Adequate sleep regulates critical hormones such as insulin and cortisol, reduces inflammatory markers, and moderates the sympathetic nervous system, all vital for optimal glucose metabolism.</p>
        <p>To enhance sleep quality, establish a consistent sleep schedule, avoid caffeine and large meals close to bedtime, avoid electronic devices, and create a sleep environment that is quiet, dark, and cool (<Reference content='between 65-68 degrees' url='https://www.sleepfoundation.org/bedroom-environment/best-temperature-for-sleep'/>).</p>
        <p>Consider getting a sleep study if you experience symptoms like snoring, daytime fatigue, or interrupted sleep. Sleep apnea can significantly impact sleep quality and should be properly diagnosed and treated.</p>
      </>,
    products: [ProductCode.SLEEP_APNEA_TEST]
  },

  { code: Recommendation.MONITOR_TESTOSTERONE,
    kind: RecommendationKind.TESTING,
    title: () => <>Monitor testosterone levels</>,
    description: ({isMember}) => 
        <>
        <p>Consider testing your testosterone and related sex hormone biomarkers in 3 months. If your levels are declining, consult your primary care provider or an endocrinologist to help determine the underlying issue and best next steps.</p>
        <EmailConcierge subject="Sex+Hormone+Tests" isMember={isMember}/>
        </>,
  },
  { code: Recommendation.MODY_TEST,
    kind: RecommendationKind.TESTING,
    title: () => `Consider MODY genetic test`,
    description: ({isMember}) => 
      <><p>Your elevated glucose levels combined with a low HOMA-IR, suggest the possibility of MODY2, a genetic condition affecting insulin regulation due to mutations in the GCK gene. This form of diabetes presents with mild hyperglycemia from birth and is often discovered incidentally.</p>
      <p>The way to confirm this is through a genetic test. If confirmed, you should talk to your physician about this condition and see if there are other measures that should be taken beyond what you're already doing for diet and exercise.</p>
      <EmailConcierge subject="MODY+genetic+test" isMember={isMember}/>
      </>,
  },
  {
    code: Recommendation.SELENIUM,
    kind: RecommendationKind.SUPPLEMENT,
    title: () => 'Add selenium to diet or supplement',
    description: () => <>
    <p>Selenium is a crucial trace mineral that plays a key role in regulating TSH levels and supporting overall thyroid health. Its antioxidant properties also help protect the thyroid gland from oxidative stress and inflammation.</p>
    
    <p>While the recommended dietary allowance (RDA) for selenium is 55 mcg per day, research indicates that higher intake—around 200 mcg daily—may better support optimal thyroid function. Consider incorporating selenium-rich foods or supplements into your diet. When choosing supplements, opt for those containing selenomethionine, as it is more effectively absorbed than other forms such as selenite or selenate.</p>

    <p>
      <u>Monitor levels</u>: Reassess your thyroid function in three months to evaluate the effectiveness of your supplementation. Adjust your dosage if necessary based on your results.
    </p>
    </>,
    foodList: () => {
      return {
        items: [
          {supplement: ProductCode.SELENIUM, title: "Supplement"}, 
          {foods:foodData.HIGH_SELENIUM, title:"Foods"}
        ]
      }
    },
    products: [ProductCode.SELENIUM],
    hideProduct: true
  },
  { code: Recommendation.URIC_ACID_MEDICATION,
    kind: RecommendationKind.MEDICATION,
    title: ({uricAcidMedicationAlready}) => <>{uricAcidMedicationAlready ? `Continue uric acid-lowering medication` : `Consider uric acid-lowering medication`}</>,
    description: ({uricAcidMedicationAlready}) => (
      <p>{uricAcidMedicationAlready 
          ? `Since your physician has already prescribed you medication, it's reasonable to continue testing every 3 months to ensure that it is indeed helping you achieve your uric acid goals.` 
          :  `Given your current risk, medication is advisable. Allopurinol is commonly prescribed as a first-line treatment to reduce your uric acid levels, significantly cutting your risk for gout or gouty arthritis (joint pain and inflammation). Their efficacy and safety are well-supported by extensive outcome trials.`}
      </p>
    ),
    products: [ProductCode.HEART_HEALTH_MEMBERSHIP]
  },
  {
    code: Recommendation.REDUCE_ALCOHOL,
    kind: RecommendationKind.NUTRITION,
    title: () => 'Lower alcohol intake',
    description: () => <p>Your level of alcohol consumption can impair insulin sensitivity and cause the accumulation of fat in the liver, which increases your risk of type 2 diabetes and fatty liver disease. Try to reduce to one drink per day.</p>
  },

  // SECTION: FOLLOW UP TESTS
  { code: Recommendation.ANA_COMPREHENSIVE_PANEL,
    kind: RecommendationKind.TESTING,
    title: () => <>Get comprehensive autoimmune panel done</>,
    description: ({isMember}) => 
      <>  
      <p>A comprehensive autoimmune panel typically includes ANA, anti-dsDNA, anti-SSA/SSB, anti-Smith, RF/Anti-CCP, complement levels, ESR/CRP, and urinalysis. These tests will provide a broad assessment of potential autoimmune activity and can help identify conditions such as lupus, rheumatoid arthritis, Sjögren's syndrome, and scleroderma.
      </p>

      <p>Consider scheduling these tests within the next 3 months. If there's anything flagged, it's a good idea to consult your primary care provider or a rheumatologist (autoimmune specialist) to explore the cause and discuss the best course of action.</p>
 
      <EmailConcierge subject="Comprehensive-Autoimmune+Panel" isMember={isMember}/>
      </>
  },
  { code: Recommendation.REPEAT_RENAL_TESTS,
    kind: RecommendationKind.TESTING,
    title: () => <>Monitor kidney function</>,
    description: ({hasHighCreatinine, isMember}) => 
      <>  
      <p>Testing every 3 months is recommended until your levels are back in healthy range. If there's any decline, consult your primary care provider or a kidney specialist (nephrologist) to identify the cause and determine the best next steps.</p>
      <p>A kidney function panel typically includes tests like cystatin C, BUN, creatinine, electrolytes, calcium, phosphorus, and urine tests like urinalysis and UACR, providing insights into filtration efficiency and waste removal.</p>

      {hasHighCreatinine && <p>Avoid creatine supplementation 3 weeks before the test as it may affect your creatinine levels.</p>}
      <EmailConcierge subject="Kidney+Function+Panel" isMember={isMember}/>
      </>
  },
  { code: Recommendation.REPEAT_LIVER_TESTS,
    kind: RecommendationKind.TESTING,
    title: () => <>Monitor liver function</>,
    description: ({isMember}) => 
      <>
        <p>Testing every 3 months is recommended until your levels are back in healthy range. If there's any decline, consult your primary care provider or a liver specialist (hepatologist) to identify the cause and determine the best next steps.</p>

        <p>A liver function panel typically includes tests like ALT, AST, ALP, bilirubin (total & direct), GGT, albumin, total protein. To rule out common false positives next time, it's advised to avoid strenuous exercise and drinking alcohol for 48 hours prior to your blood draw.</p>

      <EmailConcierge subject="Liver+Panel" isMember={isMember} />
      </>,
  },
  { code: Recommendation.COMPREHENSIVE_LIVER_PANEL,
    kind: RecommendationKind.TESTING,
    title: () => <>Get comprehensive liver panel done</>,
    description: ({isMember}) => 
      <>
      <p>
      Tests to help rule out any liver issues should include hepatic panel, iron panel, alpha 1 phenotype, ceruloplasmin, viral hepatitis panel, ANA, and ASMA. To rule out false positives, it's advised to avoid strenuous exercise and drinking alcohol for one week prior to your blood draw.
      </p>
      <EmailConcierge subject="Comprehensive+Liver+Panel" isMember={isMember} />
      </>,
  },
  { code: Recommendation.HEPATITIS_PROFILE,
    kind: RecommendationKind.TESTING,
    title: () => <>Consider getting hepatitis profile done</>,
    description: ({isMember}) => 
      <>
      <p>
      Hepatitis is a common cause of elevated liver enzymes and includes different types, such as viral, autoimmune, and drug-induced hepatitis. Given the uncertainty surrounding the cause of your elevated ALT and AST levels, you may want to just go ahead and test for it to rule it out. 
      </p>
      <EmailConcierge subject="Hepatitis+Profile" isMember={isMember} />
      </>,
  },
  { code: Recommendation.REPEAT_CBC,
    kind: RecommendationKind.TESTING,
    title: () => <>Repeat complete blood count test</>,
    description: ({isMember}) => 
      <>      
      <p>Consider retesting within a month to determine if this issue is transient or persistent. If the latter, you should consult your primary care provider or hematologist, an expert in blood cell health, to help determine the underlying issue and appropriate next steps. </p>
      <EmailConcierge subject="CBC" isMember={isMember}/>
      </>
  },
  {
    code: Recommendation.DEXA,
    kind: RecommendationKind.TESTING,
    title: () => "Get DEXA scan done",
    description: () => <p>A DEXA scan measures visceral fat, which surrounds vital organs and plays a critical role in metabolic health. High levels contribute to insulin resistance, inflammation, and an increased risk of metabolic disorders. Based on your results, we can determine if an even more aggressive approach is needed to improve your metabolic health.</p>,
    products: [ProductCode.DEXA_BODYCOMP]
  },  
  {
    code: Recommendation.DEXA_MUSCLEHEALTH,
    kind: RecommendationKind.TESTING,
    title: ({isPregnant}) => isPregnant ? "Get DEXA scan done after giving birth" : "Get DEXA scan done",
    description: ({isPregnant}) => <>
      <p>For a more detailed evaluation of your muscle health, schedule a DEXA scan. Focus on two key metrics: Appendicular Lean Mass Index (ALMI) and Bone Mineral Density (BMD).</p><p>ALMI measures muscle mass relative to height, offering insights into muscle function and strength, while BMD assesses bone health, which is essential for maintaining strength and mobility. Monitoring both will help guide your training and reduce the risk of future muscle and bone-related issues.</p>
      
      
      {isPregnant && <p>Note: DEXA scans are not recommended during pregnancy. Although the radiation dose from a DEXA scan is very low, it's still advisable to avoid unnecessary radiation exposure during pregnancy, particularly in the first and second trimesters when fetal development is most sensitive.</p>}
    </>,
    products: [ProductCode.DEXA_BODYCOMP]
  },
  {
    code: Recommendation.DIURNAL_CORTISOL,
    kind: RecommendationKind.TESTING,
    title: () => <>Get diurnal cortisol test done</>,
    description: () => <><p>A diurnal cortisol test measures your cortisol levels throughout the day, providing insights into your body's cortisol production and response. This test can help identify potential issues with your cortisol production and regulation, which may be linked to conditions like Addison's disease or Cushing's syndrome.</p></>,
    products: [ProductCode.DIURNAL_CORTISOL]
  },

  // SECTION: PROVIDER REFERRALS
  {
    code: Recommendation.TALK_TO_PCP_IRON,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to physician about iron deficiency`,
    description: () => <><p>It's recommended to discuss with your physician on why your ferritin is low. They may suggest additional tests or procedures to gain a clearer understanding the source of your iron deficiency.</p></>
  },
  { code: Recommendation.TALK_TO_PCP_CGM,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to physician to review your CGM data`,
    description: () => 
    <>      
      <p>
      Using a CGM is a great proactive step on your part as it offers insightful data on your body's glucose response to various factors. Consulting a physician specalizing in metabolic health can transform this data into a personalized action plan, targeting dietary, exercise, and stress management strategies for optimal glucose control.
      </p>
    </>,
    products: [ProductCode.HEART_HEALTH_MEMBERSHIP]
  },
  { code: Recommendation.TALK_TO_PCP_MODY,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to physician about MODY`,
    description: () => <><p>Your elevated glucose and hbA1c levels, combined with a low HOMA-IR, suggest the possibility of MODY2, a genetic condition affecting insulin regulation due to mutations in the GCK gene. This form of diabetes presents with mild hyperglycemia from birth and is often discovered incidentally.</p>
    <p>It's recommended to talk to your physician about this condition and if confirmed to see if there are other measures that should be taken beyond what you're already doing for diet and exercise.</p></>,
    products: [ProductCode.HEART_HEALTH_MEMBERSHIP]
  },
  {
    code: Recommendation.MIRABEGRON_SIDE_EFFECT,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to physician about side effects of Myrbetriq`,
    description: () => <><p>Myrbetriq (mirabegron) can cause high blood pressure by stimulating cardiovascular beta-1 and beta-2 adrenergic receptors, leading to increased heart rate, contractility, and peripheral vascular resistance.</p> <p>If your high blood pressure is confirmed, it's recommended to discuss with the physician that presecribed your medication about the potential side effects and whether an alternative might be more suitable for you.</p></>
  },
  {
    code: Recommendation.TALK_TO_PCP_PROSTATE,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to physician about PSA levels`,
    description: () => <><p>It's recommended to discuss these findings with your physician. They may suggest additional tests or procedures, such as a digital rectal exam (DRE), MRI, or a biopsy, to gain a clearer understanding of your prostate health.</p></>
  },
  { code: Recommendation.TALK_TO_LIPIDOLOGIST,
    kind: RecommendationKind.PROVIDER,
    title: () => `Discuss results with a clinical lipidologist`,
    description: ({hasHighCAC}) => 
      <p>
      <>Discuss your {hasHighCAC ? 'high CAC and elevated lipid levels' : 'elevated lipid levels'} with a clinical lipidologist to evaluate your condition and explore additional measures for effective management and risk reduction.</>
      </p>,
    referralType:  ({isAthlete}) => isAthlete ? ReferralType.LIPIDOLOGIST : null,   
    products: ({isAthlete, isHeartHealthMember}) => !isAthlete && !isHeartHealthMember ? [ProductCode.HEART_HEALTH_MEMBERSHIP] : [] 
  },
  { code: Recommendation.TALK_TO_PCP_CAC,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to physician about your heart health`,
    description: ({hasHighCAC}) => 
      <p>
      <>Discuss your CAC score with a clinical lipidologist or cardiologist, who are experts in evaluating your condition and can explore additional measures for effective management and risk reduction.</>
      </p>,
    referralType:  ({isAthlete}) => isAthlete ? ReferralType.LIPIDOLOGIST : null,   
    products: ({isAthlete, isHeartHealthMember}) => !isAthlete && !isHeartHealthMember ? [ProductCode.HEART_HEALTH_MEMBERSHIP] : [] 
  },
  { code: Recommendation.TALK_TO_NUTRIONIST,
    kind: RecommendationKind.PROVIDER,
    title: () => `Consult with a nutritionist`,
    description: () => 
      <p>
       A nutritionist can help with your insulin resistance by reviewing your current dietary habits and creating personalized meal plans that balance your blood sugar and improve insulin sensitivity, tailored to your likes and lifestyle.
      </p>,
    referralType: () =>  ReferralType.NUTRIONIST,    
  },
  { code: Recommendation.TALK_TO_PCP_LOW_PLATELET,
    kind: RecommendationKind.PROVIDER,
    title: () => <>Talk to your physician about low platelet count</>,
    description: () => <p>Given repeat testing shows this is likely not transient, your physician or hematologist, a doctor who specializes in blood disorders, can help determine the underlying issue and treatment strategy if needed.</p>,
    referralType: () =>  ReferralType.HEMATOLOGIST
  },
  { code: Recommendation.TALK_TO_PCP_INSULIN_RESISTANCE,
    kind: RecommendationKind.PROVIDER,
    title: () => `Work with metabolic health specialist`,
    description: () => 
      <p>
       A physician specailizing in metabolic health can review your diet and create a custom plan that helps balance blood sugar and improve insulin sensitivity—tailored to your preferences and lifestyle.
      </p>,
    products: ({isHeartHealthMember}) => isHeartHealthMember ? [ProductCode.HEART_HEALTH_CONSULT] : [ProductCode.HEART_HEALTH_MEMBERSHIP] 
  },
  { code: Recommendation.TALK_TO_RHEUMATOLOGIST,
    kind: RecommendationKind.PROVIDER,
    title: () => `Discuss results with a rheumatologist`,
    description: () => 
      <p>
       A specialist in autoimmune diseases can assess your results and any symptoms you have in detail to determine whether further diagnostic tests or treatment is necessary.
      </p>,
    referralType: () => ReferralType.RHEUMATOLOGIST
  },
  { code: Recommendation.TALK_TO_PCP_THYROID,
    kind: RecommendationKind.PROVIDER,
    title: () => `Talk to physician about possible thyroid-related issues`,
    description: () => 
      <p>An endocronologist, a specialist in thyroid health, can help identify the underlying cause of your abnormal thyroid levels, whether it's related to autoimmune activity, lifestyle factors, or other conditions. Together, you can discuss appropriate treatment options if needed, which may include lifestyle changes, medication, or further testing.</p>,
    referralType: () => ReferralType.ENDOCRINOLOGIST
  },

];

export default RecommendationData;