import { Form, message, Button, Typography } from "antd"
import "./cardForm.scss"
import { EditOutlined } from '@ant-design/icons'
import { useState, useEffect } from "react"
import Color from '../../colors.scss'
import { addCard, getCard } from '../../services/card.service';
import { useContext } from "react"
import { UserContext } from "../../contexts/user.context"
import { listCards } from "../../services/card.service"
import { Elements, ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure'
import ReadableCardBrand from "../../enums/readableCardBrand.enum"
import { cardLogos } from '../../utils/cardLogos';
import PaymentMethodsModal from "../paymentMethodsModal/paymentMethodsModal.component";
const { Item } = Form
const { Text } = Typography

// Move this outside the component, at the top level after imports
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
const cardSelect = '_id brand last4 isRemoved stripeId expMonth expYear isDefault'

export const CardForm = (props={}) => {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({stripe, elements}) => (
          <CardFormChild
            stripe={stripe}
            elements={elements}
            {...props}
          />
        )}
      </ElementsConsumer>
    </Elements>
  )
}

const CardFormChild = ({ 
  stripe, 
  elements, 
  onSuccess, 
  onNextStep, 
  isLoading, 
  setIsLoading, 
  buttonText, 
  disclaimerText, 
  activeCard, 
  setActiveCard, 
  currentMembership,
  flow, 
  hasSkip=false, 
  hideButton=false 
}) => {

  const [allCards, setAllCards] = useState([])
  const [cards, setCards] = useState([])
  const [form] = Form.useForm()
  const [openCardModal, setOpenCardModal] = useState(false)

  useEffect(() => {
    refreshCards()
  }, [flow])

  useEffect(() => {
    onSetDefault()
  }, [cards, flow])

  const onChangeCard = async (cardId) => {
    setOpenCardModal(true)
  }

  const getCardParams = async () => {
    const cardNumber = elements.getElement(CardNumberElement)
    const cardExpiry = elements.getElement(CardExpiryElement)
    const cardCvc = elements.getElement(CardCvcElement)

    if (!cardNumber || !cardExpiry || !cardCvc) {
      throw new Error('Card details are required')
    }
    const stripeResponse = await stripe.createSource(cardNumber)
    
    const { error, source } = stripeResponse
    if (error) {
      throw error
    }

    const {
      exp_month: expMonth,
      exp_year: expYear,
      country,
      last4,
      brand
    } = source.card

    return {
      stripeId: source.id,
      expMonth,
      expYear,
      country,
      last4,
      brand,
    }
  }

  const onConfirm = async () => {
    try {
      setIsLoading(true)

      if (cards?.length && activeCard) {
        // console.log('existing card', activeCard)
        setActiveCard(activeCard)
        onSuccess(activeCard)
        if (!cards?.some(({ _id }) => _id === activeCard?._id)) {
          await refreshCards()
        }
      } else {
        // console.log('add new card')
        let fields = await getCardParams()
        const card = await addCard({
          fields,
          flow: flow,
          select: cardSelect
        })

        if (card) {
          setActiveCard(card)
          onSuccess(card)
        }
      }
    } catch (error) {
      // More user-friendly error message
      message.error('Please enter valid card details')
      setIsLoading(false)
    } finally {
      //setIsLoading(false)
    }
  }

  const refreshCards = async () => {
    const allCards = await listCards({select: cardSelect, flow: flow?._id})
    const activeCards = allCards.filter((card) => !card.isRemoved)
    setAllCards(allCards)
    setCards(activeCards)
    return activeCards
  }

  const onSetDefault = async (cardId) => {
    if (cards?.length) {
      let existingCard = cards.find(card => {
        if (cardId) {
          return card._id === cardId
        } else if (currentMembership?.card?._id) {
          return card._id === currentMembership.card._id
        } else {
          return card.isDefault
        }
      })
      if (!existingCard && cards?.length) {
        existingCard = cards.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0]
      }
      setActiveCard(existingCard)
      if(existingCard && existingCard._id  !== activeCard?._id) {
        setOpenCardModal(false)
      }
    }
    else {
      setActiveCard(null)
    }
  }


  const elementOptions = {
    style: {
      base: {
        iconColor: 'lightgray',
        color: Color.primary_text,
        fontFamily: 'Lato, sans-serif',
        margin: 30,
        fontSize: '18px',
        fontWeight: '400',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: Color.secondary_text,
        },
        '::placeholder': {
          color: Color.secondary_text,
        },
      },
      invalid: {
        iconColor: Color.error,
        color: Color.error,
      },
    },
    disableLink: false,
    iconStyle: 'solid',
    showIcon: true,
  }

  return (
    <div className="card-form">
      <PaymentMethodsModal
        open={openCardModal}
        setOpen={setOpenCardModal}
        activeCardId={activeCard?._id}
        onSetDefault={onSetDefault}
        onCardUpdate={refreshCards}
        flow={flow}
      />

      <Form
        form={form}
        className="pay-form"
        layout="vertical"
      >
        <div className="pay-fields">
          {activeCard ? (
            <div className="saved-card-details">
              <span className="saved-card-logo">
                {cardLogos[activeCard.brand?.toLowerCase().replace(/ /g, '_')]}
              </span>

              <div className="saved-card-content">
                <Text className="saved-card-label">
                  Confirming payment will bill
                </Text>
                <Text className="saved-card-description">
                  {ReadableCardBrand[activeCard.brand] || activeCard.brand} ending in {activeCard.last4}
                </Text>
              </div>


              <Button
                className="edit-btn"
                icon={<EditOutlined />}
                onClick={() => onChangeCard(activeCard._id)}
              />

            </div>
          ) : ( 
            <div className="card-input-fields">
              <Item
                label='Card Number'
                className="pay-item"
              >
                <CardNumberElement options={elementOptions}/>
              </Item>
              
              <div className="card-row">
                <Item
                  label='Expiry'
                  className="pay-item expiry"
                >
                  <CardExpiryElement options={elementOptions}/>
                </Item>

                <Item
                  label='CVC'
                  className="pay-item cvc"
                >
                  <CardCvcElement options={elementOptions}/>
                </Item>
              </div>
            </div>
          )}
        </div>

        {disclaimerText && (
          <div className="card-form-disclaimer">
            {disclaimerText}
          </div>
        )}

    

        {!hideButton && (
          <>
          <Button
            type='primary'
            className='confirm-btn'
            onClick={onConfirm}
            loading={isLoading}
          >
            {buttonText || 'Confirm Payment with Card'}
          </Button>


          {hasSkip && <Button
            type='default'
            className="skip-btn"
            onClick={onNextStep}
          >
            No thanks
          </Button>}
          </>
        )}
        
      </Form>
    </div>
  )
}