import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import './testChartDrawer.scss';
import { TestChart } from '../testChart/testChart.component';

export const TestChartDrawer = ({ 
  open,
  setOpen,
  test,
  setTest
}) => {
  useEffect(() => {
    if (!open) {
      setTest(null)
    }
  }, [open])

  return (
    <Drawer
      title={test?.name}
      placement="right"
      onClose={() => setOpen(false)}
      open={open}
      width={'90%'}
      className="test-chart-drawer"
    >
      <TestChart
        id={test?._id}
      />
    </Drawer>
  );
};