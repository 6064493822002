import React, { useRef, useState, useEffect } from 'react';
import {RightOutlined, LeftOutlined} from '@ant-design/icons'
import "./smallBiomarkerPanel.scss";

export const SmallBiomarkerPanel = ({ panel }) => {
  const panelRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (panelRef.current) {
        setIsOverflowing(panelRef.current.scrollWidth > panelRef.current.clientWidth);

        console.log(panelRef.current.scrollWidth, panelRef.current.clientWidth)
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [panel]);

  const scroll = (direction) => {
    if (panelRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300; // Adjust scroll amount as needed
      panelRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return panel && (
    <div className="product-details-small-biomarker-panel-container">
      <div className="product-details-small-biomarker-panel" ref={panelRef}>
        {panel.map((biomarker, index) => (
          <div 
            className={`biomarker ${index === panel.length - 1 ? 'last-biomarker' : ''}`} 
            key={biomarker.name}
          >
            <div className="biomarker-name">{biomarker.name}</div>
            <div className="biomarker-description">{biomarker.description}</div>
          </div>
        ))}
      </div>
      {isOverflowing && (
        <div className="controls">
          <a className="see-more-link" onClick={() => scroll('right')}>See More</a>
          <button className="scroll-button" onClick={() => scroll('left')}><LeftOutlined/></button>
          <button className="scroll-button" onClick={() => scroll('right')}><RightOutlined /></button>
        </div>
      )}
    </div>
  );
};