import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import { Form, Input, Button, message, Tabs } from 'antd'
import { login } from '../../services/auth.service.js'
import './login.scss'
import { ForgotPasswordModal } from '../forgotPasswordModal/forgotPasswordModal.component.js';
import { UserContext } from '../../contexts/user.context.js';
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper';
import StorageKey from '../../enums/storageKey.enum.js';
import { Signup }  from '../signup/signup.component.js';

const { Item } = Form

export const Login = ({ isModal, onLoginSuccess, redirectPath, isSignup, title }) => {
  const [form] = Form.useForm()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState()
  const [openModal, setOpenModal] = useState()
  const { setToken, token } = useContext(UserContext)
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt
  
  const [activeTab, setActiveTab] = useState(() => {
    return searchParams.get('tab') === 'signup' ? 'signup' : 'login'
  })

  useEffect(() => {
    if (!isModal) {
      document.title = 'Instalab | Sign in'
      document.description = 'Sign in or create a new account.'
    }
  }, [isModal])

  useEffect(() => {
    if (token) {
      if ( onLoginSuccess) {
        console.log("login component onlogin success")
        onLoginSuccess()
      }
      const redirect = isModal ? redirectPath : searchParams.get('redirect')
      navigate(redirect || '/')
    }
  }, [token, isModal, redirectPath])

  const onFail = () => {
    setHasAttempt(true)
    message.error('Enter valid account data')
  }

  const onFinish = async () => {
    setIsLoading(true)
    try {
      const response = await login(form.getFieldsValue())
      localStorage.setItem(StorageKey.TOKEN, response.token)
      setToken(response.token)
      message.info('Logged in')
    } catch (err) {
      message.error(err.response?.data?.message || 'Failed to log in')
    }
    setIsLoading(false)
  };

  return <div className={`login-page ${isModal ? 'modal-login' : ''}`}>
    <ForgotPasswordModal
      open={openModal}
      setOpen={setOpenModal}
    />
    
    <div className="login-form">
      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        items={[
          {
            key: 'login',
            label: 'Login',
            children: (
              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFail}
                layout='vertical'
              >
                <h1>{title ? title : isSignup ? 'Create Account' : 'Welcome Back'}</h1>
                <Item 
                  label="Email Address"
                  name="email"
                  onInput={e => e.target.value = e.target.value.toLowerCase()}
                  rules={[
                    RuleHelper.isRequired,
                    RuleHelper.isEmail,
                  ]}
                  validateTrigger='onSubmit'
                >
                  <Input 
                    placeholder="Email Address" 
                    onChange={() => {
                      if (hasAttemptRef.current) {
                        FormHelper.fetchHasError(form)
                      }
                    }}
                  />
                </Item>

                <Item 
                  label="Password"
                  name="password"
                  rules={[
                    RuleHelper.isRequired,
                  ]}
                  validateTrigger='onSubmit'
                >
                  <Input.Password 
                    placeholder="Password" 
                    onChange={() => {
                      if (hasAttemptRef.current) {
                        FormHelper.fetchHasError(form)
                      }
                    }}
                  />
                </Item>

                <Item>
                  <Button 
                    htmlType='submit'
                    type="primary" 
                    loading={isLoading}
                  >
                    Log in
                  </Button>
                </Item>

                <div className="login-links">
                  <a onClick={() => setOpenModal(true)}>Need help logging in?</a>
                </div>
              </Form>
            )
          },
          {
            key: 'signup',
            label: 'Create Account',
            children: (
              <div className="signup-redirect">
               
               <Signup />
              </div>
            )
          }
        ]}
      />
    </div>
  </div>
}