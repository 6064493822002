import { useContext, useRef, useState, useEffect } from "react"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { ConfirmReferral } from "./confirmReferral.component"
import { UserContext } from "../../contexts/user.context"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import { ReferralIntro } from "./referralIntro.component"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"
import ReferralType from "../../enums/referralType.enum"
import _ from 'lodash';
import { completeFlow } from "../../services/flow.service.js"


export const Step = {
  INTRO: 'intro',
  ACCOUNT: 'account',

  // referral info
  REFERRAL: 'referral-type',
  REASON: 'reason',
  INSURANCE: 'insurance',

  // basic account info 
  NAME: 'name',
  DOB: 'dob',
  LOCATION: 'location', 
  MEMBERSHIP: 'membership',

  CONFIRM: 'confirm',
}

export const ReferralFlow = () => {
  const navigate = useNavigate()
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const { instalabMembership } = useContext(UserContext)
  const [productTypes, setProductTypes] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [hasPass, setHasPass] = useState()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser
  const hasCompletedFlowRef = useRef(false)


  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  useEffect(() => {
    fetchSkeleton()
  }, [hasPass, membershipTypes, productTypes, instalabMembership, flow])
  

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.SPECIALIST_REFERRAL,
          ]
        }
      }
    }))
  }

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    }))
  }

  const instructions = [
    {
      sections: [{
        title: 'Request a Specialist Referral',
        description: <>Tell us about the type of specialist you’re seeking—whether it’s a gastroenterologist for a colonoscopy, dermatologist for skin cancer screening, or anything else.</>
      }, 
    ]
    }, 
    {
      sections: [{
        title: 'We’ll Handle the Scheduling',
        description: <>Instalab’s concierge team will research top specialists, provide you with recommendations, and handle the appointment scheduling for you.</>
      }]
    },
    {
      sections: [{
        title: 'Seamless Follow-Up',
        description: <>After your appointment, we’ll check in to review any results (if applicable) and assist with scheduling follow-up visits or additional care if needed.</>
      }]
  }]

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.ACCOUNT,
        content: ({ footer }) => <ReferralIntro  footer={footer} instructions={instructions}/>
      },

      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.REFERRAL,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>This will help you manage your requests. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.REFERRAL}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      

      [Step.REFERRAL]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.REASON,
        buildFlow: true,
        title: `What type of referral are you looking for?`,
        field: {
          name: 'referralType',
          options: [ ...Object.values(ReferralType)
          .map((type) => ({
            label: _.capitalize(type),  
            value: type              
          })).sort((a, b) => a.label.localeCompare(b.label)), {
            label: "Other",
            value: 'other'
          }]
        }
      },

      [Step.REASON]: {
        type: StepType.TEXTAREA,
        nextStep: Step.INSURANCE,
        buildFlow: true,
        title: `What's the reason for this referral request?`,
        description: 'Specifics will help us find the best referral for you.',
        field: {
          name: 'reason',
          placeholder: 'Type here ...',
        }
      },


      [Step.INSURANCE]: {
        type: StepType.UPLOAD,
        title: 'Add insurance: front and back of insurance card.',
        description: `You can upload one file at a time. Please skip this step if you don't want us to prioritize referrals that accept your insurance.`,
        nextStep: Step.NAME,
        buildFlow: true,
        field: {
          name: 'insurance'
        }
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.LOCATION,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        buildFlow: true,
        nextStep: Step.MEMBERSHIP,
        title: `What's your current address?`,
        description: `We'll find referrals near this address.`,
        field: {
          name: 'location'
        },
      },

      [Step.MEMBERSHIP]: {
        type: StepType.PAY,
        nextStep: Step.CONFIRM,
        addSubscription: true,
        subscriptionType: MembershipTypeCode.LONGEVITY,
        title: <>Activate Instalab membership for <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span>.</>,
        description: "This feature is exclusive to members, who also get their own longevity physician, care concierge and more. Easily cancel anytime.",
        skipIf: () => {
          return instalabMembership
        }
      },

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: <>You’re all set! 🎉</>,
        description: `Our concierge team is will begin research shortly and email you within 1 business day.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmReferral instructions={instructions} />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            console.log(flow)
            await completeFlow(flow._id);
          }
        }
      },

    })
  }

  return <>
    <Flow 
      skeleton={skeleton} 
      flow={flow}
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />  
  </>
}