import React, { useState, useEffect, useContext } from "react";
import { Table, Tooltip, Spin } from "antd";
import { UserContext } from "../../contexts/user.context";
import TestHelper from "../../helpers/test.helper.js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import classNames from "classnames";
import { ReadableRiskLevel, RiskLevel, TestCode } from "../../enums/index.enum";
import { useSearchParams } from "react-router-dom";
import "./singleResultsTable.scss";
import ReportHelper from "../../helpers/report.helper.js";
import useWidth from "../../hooks/useWidth.hook.js";
import Breakpoint from "../../enums/breakpoint.enum";
import ResultHelper from "../../helpers/result.helper.js";
import { SyncOutlined } from "@ant-design/icons";
import moment from 'moment'
import PatientHelper from "../../helpers/patient.helper.js";
import { TestChartDrawer } from "../testChartDrawer/testChartDrawer.component.js";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime); 

const CHANGE_THRESHOLD = 10; // 10%
const CHANGE_THRESHOLD_MINI = 25; 

const TREND_BLACKLIST = [
  TestCode.LP_PLA2
]

export const SingleResultsTable = ({ 
  filterValue, 
  blacklist, 
  testorderLookup,
  tests,
  results,
  reports,
  patientId,
  absoluteCategories,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTest, setSelectedTest] = useState()
  const { currentUser } = useContext(UserContext);
  const [incomingTests, setIncomingTests] = useState([])
  const [searchParams, setSearchParams] = useSearchParams();
  const width = useWidth()
  const [dataSource, setDataSource] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const testId = searchParams.get('test');
    if (testId) {
      if (testId === 'health-score') {
        setSelectedTest({
          _id: "health-score",
          name: "Longevity Score",
        })
      } else {
        setSelectedTest(tests.find(({ _id }) => _id === testId));
      }
      setOpenDrawer(true)
    } else {
      setSelectedTest(null)
      setOpenDrawer(false)
    }
  }, []);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (selectedTest) {
      // Add or update test param
      newSearchParams.set('test', selectedTest._id);
    } else {
      // Remove only the test param
      newSearchParams.delete('test');
    }
    
    // Update URL while preserving other params
    setSearchParams(newSearchParams);
  }, [selectedTest])


  useEffect(() => {
    fetchLatestResults();
  }, [patientId, results, reports, currentUser, absoluteCategories, filterValue]);

  const fetchLatestResults = async () => {
    setIsLoading(true);
    try {

      const progressData = {
        ...(results[1] ? ResultHelper.getProgressData(results[1]) : {}),
        ...(results[0] ? ResultHelper.getProgressData(results[0]) : {})
      }

      let incompleteTests = progressData?.incompleteTests

      if (incompleteTests?.length > 0) {
        if (moment(results[0]?.collectedAt).add(2.5, 'weeks') > moment()) {
          if (!PatientHelper.isAthlete(results[0]?.patient)) incompleteTests.push({_id: "health-score", name: "Longevity Score"})
          setIncomingTests(incompleteTests);
        }
  
      }
      // Mapping latest and previous result for each test
      const latestResultsMap = results.reduce((acc, result) => {
        result.values.forEach((value) => {

          // Fetch the test object by its ID (value.test)
          const test = tests.find((testItem) => testItem._id === value.test);
          if (!test) return;

          const testCode = test.code; // Get the code from the test object
          if (blacklist.includes(testCode)) return;

          // Determine if the result is incomplete
          const isIncomplete = incompleteTests?.some(incompleteTest => incompleteTest._id === test._id);
          if (value.value==="") return

          if (!acc[testCode] || new Date(result.collectedAt) > new Date(acc[testCode].collectedAt)) {

            // Hack to not show BMI to athletes
            // const {patient} = results[0]              
            // // if ([TestCode.BMI, TestCode.WAIST].includes(testCode) && (PatientHelper.isAthlete(patient))) {
            // //   return
            // // }

            acc[testCode] = {
              testName: test?.name || '',
              goal: getOptimalRange(test) !== undefined && getOptimalRange(test) !== null ? getOptimalRange(test) : '',                
              value: value.value,
              unit: test?.unit || '',
              collectedAt: result.collectedAt,
              risk: value.risk || RiskLevel.UNKNOWN,
              testId: test?._id,
              absoluteCategory: test?.absoluteCategory || null,
              testCode: test?.code,
              isIncomplete: isIncomplete,
              description: test?.tagline,
              pctChange: TREND_BLACKLIST.includes(testCode) ? null : value.pctChange,
              hasImproved: TREND_BLACKLIST.includes(testCode) ? null : value.hasImproved
            };

          }
        
        });
        return acc;
      }, {});

      // Convert to array
      let latestResultsArray = Object.values(latestResultsMap);

      // Apply the filter based on the `filterValue` prop
      latestResultsArray = filterResults(latestResultsArray, filterValue);

      // Group results by category
      const resultsByCategory = absoluteCategories.map(category => ({
        ...category,
        tests: latestResultsArray.filter(result => result.absoluteCategory === category._id)
      }));

      // Sort categories based on the most recent result in each category
      resultsByCategory.sort((a, b) => {
        const latestA = a.tests.length > 0 ? Math.max(...a.tests.map(t => new Date(t.collectedAt))) : 0;
        const latestB = b.tests.length > 0 ? Math.max(...b.tests.map(t => new Date(t.collectedAt))) : 0;
        return latestB - latestA;
      });

      // Combine all results into one table with categories as headers
      const combinedDataSource = resultsByCategory.reduce((acc, category) => {
        if (category.tests.length > 0) {
          // push the category header row
          acc.push({
            key: `category-${category._id}`,
            categoryName: category.name,
            isCategory: true,
          });

          // Fetch the order map for the current category based on the category code
          const orderMap = (category.code || category.code === 0) ? (testorderLookup[category.code] || {}) : {};

          // Sort the tests based on TESTORDER_LOOKUP
          const orderedTests = category.tests.sort((a, b) => {
            const orderA = orderMap[a.testCode] !== undefined ? orderMap[a.testCode] : 999;
            const orderB = orderMap[b.testCode] !== undefined ? orderMap[b.testCode] : 999;
            return orderA - orderB;
          });

          // Add the tests to the data source
          orderedTests.forEach((test) => {
            acc.push({
              ...test,
              categoryName: "",
            });
          });
        }

        return acc;
      }, []);

      // Insert Longevity Score at the top
      const longevityScore = getLongevityScore(reports);

      if (longevityScore) {
        combinedDataSource.unshift({
          testName: 'Longevity Score',
          value: longevityScore.value,
          unit: longevityScore.unit,
          goal: '≥90', // TO DO: dynamic
          risk: longevityScore.risk,
          collectedAt: longevityScore.collectedAt,
          isLongevityScore: true,
          testId: 'health-score', 
          isIncomplete: incompleteTests?.some((test) => test._id === 'health-score'),
          pctChange: longevityScore.pctChange,
          hasImproved: longevityScore.hasImproved,
        });
        combinedDataSource.unshift({
          isCategory: true,
          categoryName: 'Longevity Score',
        });
      }

      setDataSource(combinedDataSource)


    } catch (error) {
      console.error("Error fetching latest results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getLongevityScore = (reports) => {
    // console.log('reports', reports)
    const sortedReports = reports.sort((a, b) => new Date(b.result.collectedAt) - new Date(a.result.collectedAt));
    const longevityReport = sortedReports.find(report => report.healthScore);
    if (!longevityReport) return null;
  
    // Find the previous longevity report for comparison
    const previousLongevityReport = reports
      .filter(report => report.healthScore)
      .sort((a, b) => new Date(b.result.collectedAt) - new Date(a.result.collectedAt))[1]; // Get the second latest report
  
    let pctChange = null;
    let hasImproved = null;
  
    if (previousLongevityReport) {
      const previousScore = previousLongevityReport.healthScore;
      const currentScore = longevityReport.healthScore;
  
      // Calculate percentage change
      pctChange = 100*((currentScore-previousScore)/previousScore);
  
      // Determine if it's improving
      hasImproved = currentScore > previousScore;
    }
  
    return {
      value: longevityReport.healthScore,
      risk: ReportHelper.getHealthScoreRisk(longevityReport),
      collectedAt: longevityReport.result.collectedAt,
      pctChange,
      hasImproved
    };
  };

  const filterResults = (results, filterValue) => {
    return results.filter(result => {
      // Apply the risk filtering, including Longevity Score
      switch (filterValue) {
        case "high-risk":
          return result.risk === RiskLevel.HIGH;
        case "high-moderate-risk":
          return result.risk === RiskLevel.HIGH || result.risk === RiskLevel.MODERATE;
        case "low-risk":
          return result.risk === RiskLevel.OPTIMAL;
        default:
          return true; // Show all results if no filter is selected
      }
    });
  };

  const getOptimalRange = (test) => {
    if (test.code === TestCode.APO_E) return "e3/e3, e2/e3";
    const optimalRange = test.risk.find(
      ({ level }) => level === RiskLevel.OPTIMAL
    );
    return TestHelper.formatRange(optimalRange);
  };

  const mergedColumns = [
    {
      title: "Test Name",
      dataIndex: "testName",
      key: "testName",
      fixed: "left",
      ellipsis: true,
      render: (text, record) => {
        if (record.isCategory) {
          return {
            children: <>{record.categoryName}</>,
            props: { colSpan: 1 }, // Span across all columns for category header
          };
        }

        // Check if the test is incomplete
        const isIncomplete = record.isIncomplete

        return (
          <Tooltip title={record.description || ""} placement="topLeft">
            <div className="test-name-container">
              {isIncomplete && (
                  <Tooltip title={"testresult pending"}>
                  <span className="incomplete-indicator"><SyncOutlined/></span> 
                  </Tooltip>
              )}
              {text}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Latest Result",
      dataIndex: "value",
      key: "value",
      align: "center",
      render: (value, record) => {
        if (record.isCategory) {
          return {
            children: <span style={{marginLeft: "-14px"}}>{(width >= Breakpoint.MD) ? "Result" : "Latest Result"}</span>,
            props: { colSpan: 1 }, 
          };
        }

        // Check if the test is incomplete
        const isIncomplete = record.isIncomplete

        let trendIcon;
        const threshold = value<5 ? CHANGE_THRESHOLD_MINI : CHANGE_THRESHOLD;

        // console.log(record.testName, record.pctChange, record.hasImproved)

        if (record.hasImproved && (record.pctChange>threshold))
          trendIcon = "↑";
        else if (record.hasImproved && (record.pctChange < -threshold))
          trendIcon = "↓"; 
        else if (!record.hasImproved && (record.pctChange>threshold))
          trendIcon = "↑";
        else if (!record.hasImproved && (record.pctChange < -threshold))
          trendIcon = "↓";

        return (
          <Tooltip title={isIncomplete ? "test result pending" : ReadableRiskLevel[record.risk]}>
            <div className="result-wrapper">
              <div className={classNames("risk-tag", `${record.risk}-risk`)}>
                {value}
              </div>
              {isIncomplete ? (
                  <span className="incomplete-indicator next-to-result">
                    <SyncOutlined />{" "}
                  </span>
                ) :
              <span className={`trend-icon ${trendIcon ? '' : 'no-trend-icon'} ${record.hasImproved ? 'trend-improving' : 'trend-worsening'}`}>
                {trendIcon || " "}
              </span>}
            </div>
          </Tooltip>
        );
        
      }
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      fixed: "right",
      className: "test-unit",
      width: width >= Breakpoint.LG ? null : 100,
      render: (text, record) => (record.isCategory ? { children: <>Unit</>, props: { colSpan: 1 } } : text),
    },
  ];

  // Conditionally add the "Goal" column if the width is greater than the breakpoint
  if (width >= Breakpoint.MD) {
    mergedColumns.splice(2, 0, {
      title: "Goal",
      dataIndex: "goal",
      key: "goal",
      render: (text, record) => (record.isCategory ? { children: <>Goal</>, props: { colSpan: 1 } } : text),
    });
    mergedColumns.splice(1, 0, {
      title: "Test Date",
      dataIndex: "collectedAt",
      key: "collectedAt",
      render: (collectedAt, record) =>
        record.isCategory
          ? { children: <>Test Date</>, props: { colSpan: 1 } }
          : (
            <Tooltip title={dayjs(collectedAt).fromNow()}>
              {dayjs(collectedAt).format("MM/DD/YY")}
            </Tooltip>
          ),
    });
  }

  return (
    <div className="single-results-table">
      <TestChartDrawer
        open={openDrawer}
        setOpen={setOpenDrawer}
        test={selectedTest}
        setTest={setSelectedTest}
      />

      {isLoading ? (
        <div className="loading">
          <div className="loading-text">
            <Spin /> &nbsp;&nbsp;Loading
          </div>
        </div>
      ) : (
        <>
          {/* Display the number of incoming tests below the "Latest Results" header */}
          {incomingTests.length > 0 && (
              <div className="incoming-tests-message">
              <Tooltip title={
                  <>
                  {incomingTests?.map(test => (
                    <li
                      key={`incomplete-test-${test._id}`}
                      className="incomplete-test-name"
                    >
                      - {test.name}
                    </li>
                  ))}
                  </>              
                }>
                <SyncOutlined /> {incomingTests.length} test result{incomingTests.length > 1 ? 's' : ''} still pending
                </Tooltip>
              </div>
            
          )}

          <Table          
            size="small"
            dataSource={dataSource}
            showHeader={false}
            columns={mergedColumns}
            rowKey={(record) => record.key || record.testId}
            pagination={false}
            scroll={{ x: true }}
            onRow={(record) => ({
              onClick: () => {
                if (!record.isCategory) {
                  if (record.testId === 'health-score') {
                    setSelectedTest({
                      _id: "health-score",
                      name: "Longevity Score",
                    })
                  } else {
                    setSelectedTest(tests.find(({ _id }) => _id === record.testId))
                  }
                  setOpenDrawer(true)
                }
              },
            })}
            rowClassName={(record) => (record.isCategory ? "is-category" : "")} // Add is-category class for category rows
            className="primary-table"
          />
        </>
      )}
    </div>
  );
};
