import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import 'react-quill/dist/quill.snow.css';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import { Typography, Tooltip as AntTooltip, Dropdown, Modal, Spin, message, Button } from 'antd'
import { DownOutlined, EditOutlined, BarcodeOutlined , FormOutlined, DeleteOutlined, SyncOutlined, SettingOutlined, FileTextOutlined, KeyOutlined, BorderOutlined, CheckCircleOutlined, PauseCircleOutlined, PlayCircleOutlined, PlusOutlined } from '@ant-design/icons'
import './adminHeartHealth.scss';
import dayjs from 'dayjs'
import moment from 'moment-timezone'
import { PageHeader } from '../pageHeader/pageHeader.component';
import utc from 'dayjs/plugin/utc'
import TrackingHelper from '../../helpers/tracking.helper.js';
import timezone from 'dayjs/plugin/timezone'
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import { refillPrescription, updatePrescriptionStatus } from '../../services/prescription.service.js';
import { getData } from '../../services/heart.service.js';
import classNames from 'classnames';
import PrescriptionStatus from '../../enums/prescriptionStatus.enum.js';
import { addProxyFlow } from '../../services/flow.service.js';
import FlowType from '../../enums/flowType.enum.js';
import { addCalendlyConsult } from '../../services/consult.service.js';
import { ReminderForm } from '../reminderForm/reminderForm.component.js';
import ReminderStatus from '../../enums/reminderStatus.enum.js';
import { removeReminder, updateReminder } from '../../services/reminder.service.js';
import { addImpersonation } from '../../services/impersonation.service.js';
import { PrescriptionForm } from '../prescriptionForm/prescriptionForm.component.js';
import { NoteDrawer } from '../noteDrawer/noteDrawer.component.js';
import LocationHelper from '../../helpers/location.helper.js';
import { HeartHealthIntakeDrawer } from '../heartHealthIntakeDrawer/heartHealthIntakeDrawer.component.js';
import MembershipTypeCode from '../../enums/membershipTypeCode.enum.js';
import { LipidTests } from './lipidTests.component.js';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context.js';
import Role from '../../enums/role.enum.js';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Paragraph } = Typography
const { confirm } = Modal

const shortMembershipTitle = {
  [MembershipTypeCode.ULTIMATE]: 'Ultimate',
  [MembershipTypeCode.HEART_HEALTH]: 'Heart',
  [MembershipTypeCode.PREMIUM]: 'Premium',
  [MembershipTypeCode.LONGEVITY]: 'Core'
}

const prescriptionOptions = {
  HAS_PRESCRIPTIONS: 'Has Prescriptions',
  OVERDUE: 'Overdue',
  NONE: 'None',
}

const consultOptions = {
  UPCOMING: 'Upcoming',
  RECENT: 'Recent'
}

const lipidResultsOptions = {
  LAGGING: 'Lagging'
}

const reminderOptions = {
  OVERDUE: 'Overdue',
  UPCOMING: 'Upcoming',
}

const booleanOptions = [{
  label: 'Yes',
  value: true
}, {
  label: 'No',
  value: false
}]

export const AdminHeartHealth = () => {
  const [memberships, setMemberships] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [selectedPatientId, setSelectedPatientId] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const { currentUser } = useContext(UserContext)

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      const { event, invitee } = e.data.payload
      const { uri: eventUri } = event
      const { uri: inviteeUri } = invitee

      const flow = await addProxyFlow({
        type: FlowType.HEART_MEMBER_CONSULT,
        user: selectedPatientId
      })
      
      const consultResponse = await addCalendlyConsult({
        flowId: flow._id,
        eventUri,
        inviteeUri
      })

      setMemberships(memberships.map(membership => {
        if (membership.patient._id === selectedPatientId) {
          let consults = membership.consults?.length ? membership.consults : []
          consults.push(consultResponse.consult)

          return {
            ...membership,
            consults
          }
        } else {
          return membership
        }
      }))

      setSelectedPatientId(null)

      message.info('Consult scheduled')
    },
  })

  useEffect(() => {
    document.title = 'Instalab | Heart Health'
    fetchMemberships()
  }, [])

  const fetchMemberships = async () => {
    const data = await getData()
    setMemberships(data)
    setFilteredCount(data?.length)
    setIsLoading(false)
  }

  const getRefillDate = prescription => {
    let { daysToRefill, contents, deliveredAt, createdAt, tracking } = prescription
    if (contents && !daysToRefill) {
      const { doseQty=1, units } = contents
      daysToRefill = units / doseQty
    }
    
    if (!deliveredAt && tracking) {
      const trackingEvent = tracking.trackingEvents?.find(event => 
        event.eventType.toLowerCase().includes('delivered')
      )
      if (trackingEvent) {
        deliveredAt = trackingEvent.eventTimestamp
      }
    }

    if (daysToRefill) {
      daysToRefill -= 7
      return moment(deliveredAt || createdAt).add(daysToRefill, 'days').toDate()
    }
    return null
  }

  const PrescriptionList = ({ patient, prescriptions: defaultPrescriptions }) => {
    const [prescriptions, setPrescriptions] = useState()
    const [openPrescriptionForm, setOpenPrescriptionForm] = useState()

    useEffect(() => {
      if (defaultPrescriptions) {
        setPrescriptions(defaultPrescriptions)
      }
    }, [defaultPrescriptions])

    const prescriptionTypes = prescriptions?.reduce((acc, prescription) => {
      if (!acc.includes(prescription.type)) {
        acc.push(prescription.type)
      }
      return acc
    }, [])

    const onAddPrescription = (prescription) => {
      setPrescriptions([
        prescription,
        ...prescriptions
      ])
    }

    return <>
      <PrescriptionForm
        open={openPrescriptionForm}
        setOpen={setOpenPrescriptionForm}
        onSuccess={onAddPrescription}
        patientId={patient._id}
      />

      <Button
        className="add-btn"
        onClick={() => setOpenPrescriptionForm(true)}
        icon={<PlusOutlined />}
      />

      {prescriptionTypes?.map(prescriptionType => {
        const filteredPrescriptions = prescriptions
          .filter(({ type }) => type === prescriptionType)
          .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
        const prescription = filteredPrescriptions[0]
        return (
          <PrescriptionItem 
            key={prescription._id} 
            prescription={prescription} 
          />
        )
      })}
    </>
  }

  const PrescriptionItem = ({ prescription: defaultPrescription }) => {
    const [refillDate, setRefillDate] = useState()
    const [status, setStatus] = useState()
    const [isProcessing, setIsProcessing] = useState()
    const [prescription, setPrescription] = useState()
    const [isPrescriptionItemEditOpen, setIsPrescriptionItemEditOpen] = useState()

    useEffect(() => {
      setPrescription(defaultPrescription)
    }, [defaultPrescription])

    useEffect(() => {
      fetchData()
    }, [prescription])

    const onPrescriptionItemEditSuccess = updatedPrescription => {
      setPrescription(updatedPrescription)
    }

    const fetchData = () => {
      if (!prescription) return
      const fetchedRefillDate = getRefillDate(prescription)
      setRefillDate(fetchedRefillDate)
      if (prescription.isSelfManaged && prescription.status !== PrescriptionStatus.PAUSED) {
        setStatus(PrescriptionStatus.SENT)
      } else if (prescription.status !== PrescriptionStatus.PAUSED && fetchedRefillDate < new Date()) {
        setStatus('empty')
      } else {
        setStatus(prescription.status)
      }
    }

    const onRefill = async () => {
      setIsProcessing(true)
      try {
        setPrescription(await refillPrescription(prescription._id))
        message.info('Prescription refilled')
      } catch (err) {
        message.error('Failed to refill')
      }
      setIsProcessing(false)
    }

    const onPause = async () => {
      setIsProcessing(true)
      try {
        setPrescription(await updatePrescriptionStatus(prescription._id, { status: PrescriptionStatus.PAUSED }))
        message.info(`Prescription paused`)
      } catch (err) {
        message.error('Failed to pause')
      }
      setIsProcessing(false)
    }

    const onRestart = async () => {
      setIsProcessing(true)
      try {
        setPrescription(await updatePrescriptionStatus(prescription._id, { status: PrescriptionStatus.SENT }))
        message.info(`Prescription restarted`)
      } catch (err) {
        message.error('Failed to restart')
      }
      setIsProcessing(false)
    }

    return <>
      <PrescriptionForm
        open={isPrescriptionItemEditOpen}
        setOpen={setIsPrescriptionItemEditOpen}
        onSuccess={onPrescriptionItemEditSuccess}
        prescriptionId={prescription?._id}
      />
      
      <Dropdown
        overlayStyle={{
          width: 170
        }}
        menu={{
          items: [{
            key: 'edit',
            label: (
              <a onClick={() => setIsPrescriptionItemEditOpen(true)}>
                <EditOutlined style={{ marginRight: 5 }} /> Edit
              </a>
            )
          }, {
            key: 'refill',
            label: (
              <a onClick={onRefill}>
                <SyncOutlined style={{ marginRight: 5 }} /> Refill
              </a>
            )
          }, status !== PrescriptionStatus.PAUSED && {
            key: 'pause',
            label: (
              <a onClick={onPause}>
                <PauseCircleOutlined style={{ marginRight: 5 }} /> Pause
              </a>
            )
          }, status === PrescriptionStatus.PAUSED && {
            key: 'restart',
            label: (
              <a onClick={onRestart}>
                <PlayCircleOutlined style={{ marginRight: 5 }} /> Restart
              </a>
            )
          }, prescription?.tracking?.trackingNumber && {
            key: 'tracking' ,
            label: (
              <a onClick={() => {
                window.open(TrackingHelper.getDeliveryService(prescription?.tracking?.trackingNumber).url, '_blank')
              }}>
                <BarcodeOutlined style={{ marginRight: 5 }} /> Medication Tracking
              </a>
            ),
          }]
        }}
        placement='bottom'
      >
        <AntTooltip
          title={(!prescription?.isSelfManaged && refillDate && status !== PrescriptionStatus.PAUSED) ? `Refill: ${moment(refillDate).format('MMM D, YYYY')}` : ''}
        >
          <div className="tag-container">
            <div className={classNames("tag", `${status}-tag`)}>
              {isProcessing && <Spin className="tag-spin" size='small' />}
              {prescription?.type}
            </div>
          </div>
        </AntTooltip>    
      </Dropdown>
    </>
  }

  const ConsultItem = ({ consult }) => {
    const [isProcessing, setIsProcessing] = useState()
    const weeksAgo = moment().diff(moment(consult.start), 'weeks', true)

    return (
      <Dropdown
        overlayStyle={{
          width: 150
        }}
        menu={{
          items: []
        }}
        placement='bottom'
      >
        <AntTooltip
          overlayStyle={{
            maxWidth: 500
          }}
          title={(consult.reason || consult.questions?.length) ? <>
            <Paragraph className="consult-title">
              {Math.abs(Math.round(weeksAgo))} {Math.abs(Math.round(weeksAgo)) === 1 ? 'week' : 'weeks'} {weeksAgo > 0 ? 'ago' : 'later'}
            </Paragraph>

            {consult.reason && (
              <Paragraph className="consult-reason">
                {consult.reason}
              </Paragraph>
            )}

            {consult.questions?.map(({ question, answer }, questionIndex) => (
              <div key={`consult-${consult._id}-${questionIndex}`}>
                <Paragraph className="question-title">
                  {question}
                </Paragraph>
                <Paragraph className="question-answer">
                  {answer}
                </Paragraph>
              </div>
            ))}
          </> : `${Math.abs(Math.round(weeksAgo))} ${Math.abs(Math.round(weeksAgo)) === 1 ? 'week' : 'weeks'} ${weeksAgo > 0 ? 'ago' : 'later'}`}
        >
          <div className="tag-container">
            <div className={classNames("tag", weeksAgo > 0 ? "past-consult-tag" : "future-consult-tag")}>
              {isProcessing && <Spin className="tag-spin" size='small' />}
              {moment(consult.start).format('MMM D, YYYY')}
            </div>
          </div>
        </AntTooltip>
      </Dropdown>
    )
  }

  const ReminderItem = ({ reminder, onEditReminder, onRemoveReminder, onChangeReminderStatus }) => {
    const [isProcessing, setIsProcessing] = useState()
    const weeksAgo = moment().diff(moment(reminder.scheduledAt), 'weeks', true)

    return (
      <Dropdown
        overlayStyle={{
          width: 150
        }}
        menu={{
          items: [reminder.status === ReminderStatus.PENDING ? {
            key: 'complete',
            label: (
              <a onClick={() => onChangeReminderStatus(reminder._id, true)}>
                <CheckCircleOutlined style={{ marginRight: 5 }} /> Complete
              </a>
            )
          } : {
            key: 'open',
            label: (
              <a onClick={() => onChangeReminderStatus(reminder._id, false)}>
                <BorderOutlined style={{ marginRight: 5 }} /> Open
              </a>
            )
          }, {
            key: 'edit',
            label: (
              <a onClick={() => onEditReminder(reminder._id)}>
                <EditOutlined style={{ marginRight: 5 }} /> Edit
              </a>
            )
          }, {
            key: 'remove',
            label: (
              <a onClick={() => onRemoveReminder(reminder._id)}>
                <DeleteOutlined style={{ marginRight: 5 }} /> Remove
              </a>
            )
          }]
        }}
        placement='bottom'
      >
        <AntTooltip
          overlayStyle={{
            maxWidth: 500
          }}
          title={<>
            <Paragraph className="reminder-title">
              {Math.abs(Math.round(weeksAgo))} {Math.abs(Math.round(weeksAgo)) === 1 ? 'week' : 'weeks'} {weeksAgo > 0 ? 'ago' : 'later'}
            </Paragraph>

            <Paragraph className="reminder-text">
              {reminder.text}
            </Paragraph>
          </>}
        >
          <div className="tag-container">
            <div className={classNames("tag", reminder.status === ReminderStatus.COMPLETE ? "complete-reminder-tag" : (weeksAgo >= 0 && reminder.status === ReminderStatus.PENDING) ? "overdue-reminder-tag" : weeksAgo > 0 ? "past-reminder-tag" : "future-reminder-tag")}>
              {isProcessing && <Spin className="tag-spin" size='small' />}
              {dayjs(reminder.scheduledAt).tz('America/New_York').format('MMM D, YYYY')}
            </div>
          </div>
        </AntTooltip>
      </Dropdown>
    )
  }

  const ConsultList = ({ consults: defaultConsults, patient }) => {
    const [consults, setConsults] = useState()
    const [openCalendly, setOpenCalendly] = useState()

    useEffect(() => {
      if (defaultConsults) {
        setConsults(defaultConsults)
      }
    }, [defaultConsults])

    const onAddConsult = () => {
      setSelectedPatientId(patient._id)
      setOpenCalendly(true)
    }

    return <>
      <Button
        className="add-btn"
        onClick={onAddConsult}
        icon={<PlusOutlined />}
      />

      <PopupModal
        open={openCalendly}
        url={process.env.REACT_APP_ENV === "local" ? 'https://calendly.com/instalab/devtest-clone' : "https://calendly.com/d/3r9-gjk-xjv/heart-health-consultation"}
        onModalClose={() => setOpenCalendly(false)}
        rootElement={document.getElementById("root")}
        pageSettings={{
          hideEventTypeDetails: true,
          hideLandingPageDetails: true,
        }}
        prefill={{
          firstName: patient?.firstName ? patient.firstName : '',
          lastName: patient?.lastName ? patient.lastName : '',
          name: patient?.firstName ? `${patient.firstName} ${patient.lastName}` : '',
          email: patient?.email ? patient.email : '',
        }}
      />

      {consults?.map(consult => (
        <ConsultItem
          key={`consult-${consult._id}`}
          consult={consult}
        />
      ))}
    </>
  }

  const ReminderList = ({ reminders: defaultReminders, patient }) => {
    const [reminders, setReminders] = useState([])
    const [selectedReminderId, setSelectedReminderId] = useState()
    const [openForm, setOpenForm] = useState()
    const [showAllReminders, setShowAllReminders] = useState(false)

    useEffect(() => {
      if (defaultReminders) {
        setReminders(defaultReminders)
      }
    }, [defaultReminders])

    const onAddReminder = () => {
      setOpenForm(true)
    }

    const sortReminders = reminderList => {
      return reminderList.sort((a, b) => {
        return new Date(b.scheduledAt).getTime() - new Date(a.scheduledAt).getTime()
      })
    }

    const onRemoveReminder = async (reminderId) => {
      confirm({
        title: 'Sure you want to delete this reminder?',
        content: 'This action cannot be undone.',
        onOk: async () => {
          await removeReminder(reminderId)
          setReminders(reminders.filter(({ _id }) => _id !== reminderId))
          message.info('Reminder removed')
        },
        okText: 'Yes',
        cancelText: 'No',
      });
    }

    const onEditReminder = (reminderId) => {
      setSelectedReminderId(reminderId)
      setOpenForm(true)
    }

    const onChangeReminderStatus = async (reminderId, isComplete) => {
      try {
        const updatedReminder = await updateReminder(reminderId, {
          fields: {
            status: isComplete ? ReminderStatus.COMPLETE : ReminderStatus.PENDING,
            completeAt: isComplete ? new Date() : null
          },
          select: 'text patient status scheduledAt completeAt',
        })
        setReminders(reminders.map(reminder => {
          return reminder._id === reminderId ? updatedReminder : reminder
        }))
        message.info(`Reminder ${isComplete ? 'completed' : 'opened'}`)
      } catch (err) {
        message.error(`Failed to ${isComplete ? 'complete' : 'open'} reminder`)
      }
    }
    
    return <>
      <Button
        className="add-btn"
        onClick={onAddReminder}
        icon={<PlusOutlined />}
      />

      <ReminderForm
        open={openForm}
        setOpen={setOpenForm}
        reminderId={selectedReminderId}
        setReminderId={setSelectedReminderId}
        select="text patient status scheduledAt completeAt"
        patientId={patient._id}
        onSuccess={reminder => {
          if (reminders?.some(({ _id }) => _id === reminder._id)) {
            setReminders(sortReminders(reminders.map(r => r._id === reminder._id ? reminder : r)))
          } else {
            setReminders(sortReminders([
              ...reminders,
              reminder
            ]))
          }
        }}
      />

      {reminders.slice(0, showAllReminders ? reminders.length : 3).map(reminder => (
        <ReminderItem
          key={`reminder-${reminder._id}`}
          reminder={reminder}
          onEditReminder={onEditReminder}
          onRemoveReminder={onRemoveReminder}
          onChangeReminderStatus={onChangeReminderStatus}
        />
      ))}

      {reminders.length > 3 && !showAllReminders && (
        <Button 
          onClick={() => setShowAllReminders(true)}
          type="link"
          className="view-more-button"
        >
          <DownOutlined /> View {reminders.length - 3} More
        </Button>
      )}
    </>
  }

  const ActionItems = ({ patient, notes }) => {
    const [openNoteDrawer, setOpenNoteDrawer] = useState(false)
    const [openIntakeDrawer, setOpenIntakeDrawer] = useState(false)
  
    const items = [{
      key: 'notes',
      label: <>
        <Button onClick={() => {
          setOpenNoteDrawer(true)
        }}>
          <FileTextOutlined /> Notes
        </Button>

      </>
    }, {
      key: 'impersonate',
      label: (
        <Button 
          onClick={async () => {
            try {
              const impersonate = await addImpersonation({ user: patient._id })
              copy(`${process.env.REACT_APP_CLIENT_URL}/impersonate/${impersonate._id}`)
              message.success('Copied login link')
            } catch (err) {
              message.error('Failed to generate login link')   
            }
          }}
        >
          <KeyOutlined /> Impersonate
        </Button>
      )
    }, {
      key: 'intake',
      label: (
        <Button onClick={() => setOpenIntakeDrawer(true)}>
          <FormOutlined /> Intake
        </Button>
      )
    }]

    return <>
      <HeartHealthIntakeDrawer
        open={openIntakeDrawer}
        setOpen={setOpenIntakeDrawer}
        patient={patient}
      />

      <NoteDrawer 
        notes={notes} 
        patient={patient}
        open={openNoteDrawer}
        setOpen={setOpenNoteDrawer}
      />

      <Dropdown 
        menu={{items}}
        placement='bottomRight'
        overlayClassName='menu-overlay'
      >
        <Button>
          <SettingOutlined />
        </Button>
      </Dropdown>
    </>
  }

  const getActionItems = () => []

  const handleRemindersFilter = (value, membership) => {
    if (value === reminderOptions.OVERDUE) {
      return membership.reminders.some(({ status, scheduledAt }) => status === ReminderStatus.PENDING && moment(scheduledAt).startOf('day').toDate().getTime() <= new Date().getTime())
    } else if (value === reminderOptions.UPCOMING) {
      return membership.reminders.some(({ status, scheduledAt }) => status === ReminderStatus.PENDING && moment(scheduledAt).startOf('day').toDate().getTime() > new Date().getTime())
    }
  }
  
  const handleConsultsFilter = (value, membership) => {
    if (value === consultOptions.UPCOMING) {
      return membership.consults.some(({ start }) => new Date(start).getTime() >= moment().startOf('day').toDate().getTime())
    } else if (value === consultOptions.RECENT) {
      return membership.consults.some(({ start }) => new Date(start).getTime() < moment().startOf('day').toDate().getTime() && new Date(start).getTime() >= moment().subtract(1, 'week').toDate().getTime())
    }
  }

  const handleMembershipTypeFilter = (value, membership) => {
    return membership.membershipType.code === value
  }

  const handlePrescriptionsFilter = (value, membership) => {
    if (value === prescriptionOptions.HAS_PRESCRIPTIONS) {
      return membership.prescriptions?.length
    } else if (value === prescriptionOptions.NONE) {
      return !membership.prescriptions?.length
    } else if (value === prescriptionOptions.OVERDUE) {
      const prescriptionTypes = membership.prescriptions?.reduce((acc, prescription) => {
        if (!acc.includes(prescription.type)) {
          acc.push(prescription.type)
        }
        return acc
      }, [])
      return prescriptionTypes?.some(prescriptionType => {
        const filteredPrescriptions = membership.prescriptions
          .filter(({ type }) => type === prescriptionType)
          .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
        const prescription = filteredPrescriptions[0]
        const refillDate = getRefillDate(prescription)
        return !prescription.isSelfManaged && prescription.status !== PrescriptionStatus.PAUSED && refillDate < new Date()
      })
    }
  }

  const handleLipidResultsFilter = (value, membership) => {
    if (value === lipidResultsOptions.LAGGING) {
      if (membership.membershipType.code === MembershipTypeCode.LONGEVITY || membership.membershipType.code === MembershipTypeCode.PREMIUM) return false
      if (membership.appointment) return false
      const hasNoResults = !membership.lipidResults?.length
      if (hasNoResults) return true
      return moment(membership.lipidResults[0].collectedAt).add(5, 'weeks') < moment()
    }
  }

  const getCustomFilter = (membership, value) => {
    return {
      reminders: () => handleRemindersFilter(value, membership),
      membershipType: () => handleMembershipTypeFilter(value, membership),
      patient: () => `${membership.patient.firstName} ${membership.patient.lastName}`.toLowerCase().includes(value.toLowerCase()),
      prescriptions: () => handlePrescriptionsFilter(value, membership),
      consults: () => handleConsultsFilter(value, membership),
      lipidResults: () => handleLipidResultsFilter(value, membership)
    }
  }

  return (
    <div className="admin-heart-health">
      <PageHeader
        title='Heart Health'
        count={filteredCount}
      />

      <FlexibleTable
        pageSize={20}
        isLoading={isLoading}
        records={memberships}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[{
          title: 'Membership',
          dataIndex: 'membershipType',
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.keys(shortMembershipTitle).map(code => {
            return {
              label: shortMembershipTitle[code],
              value: code
            }
          }),
          render: membershipType => {
            return shortMembershipTitle[membershipType.code]
          }
        }, {
          title: 'Patient',
          dataIndex: 'patient',
          render: patient => {
            const url = currentUser.role === Role.ADMIN ? `/patients/${patient._id}?key=Results` : `/patients/${patient._id}`
            return <a onClick={() => window.open(url, '_blank')}>{patient.firstName} {patient.lastName}</a>
          },
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Email',
          dataIndex: 'email',
          render: (_, { patient: { email } }) => {
            return (
              <a 
                onClick={() => { 
                  copy(email)
                  message.info('Email copied')
                }}
              >
                {email}
              </a>
            )
          }
        }, {
          title: 'Shipping Location',
          dataIndex: 'shippingLocation',
          render: (_, { patient: { shippingLocation } }) => {
            if (!shippingLocation) return
            return (
              <a 
                onClick={() => { 
                  copy(LocationHelper.getLocation(shippingLocation))
                  message.info('Shipping location copied')
                }}
              >
                {LocationHelper.getLocation(shippingLocation)}
              </a>
            )
          }
        }, {
          title: 'Prescriptions',
          dataIndex: 'prescriptions',
          width: 180,
          render: (prescriptions, { patient }) => (
            <PrescriptionList 
              prescriptions={prescriptions} 
              patient={patient}
            />
          ),
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(prescriptionOptions).map(value => {
            return {
              label: value,
              value
            }
          })
        }, {
          title: 'Lipid Tests',
          dataIndex: 'lipidResults',
          width: 120,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(lipidResultsOptions).map(value => {
            return {
              label: value,
              value
            }
          }),
          render: (lipidResults, { kits, appointments, patient, membershipType }) => (
            <LipidTests
              patient={patient}
              kits={kits}
              appointments={appointments}
              results={lipidResults}
              membershipType={membershipType}
            />
          )
        }, {
          title: 'Consults',
          dataIndex: 'consults',
          width: 120,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(consultOptions).map(value => {
            return {
              label: value,
              value
            }
          }),
          render: (consults, { patient }) => (
            <ConsultList 
              consults={consults} 
              patient={patient}
            />
          )
        }, {
          title: 'Reminders',
          dataIndex: 'reminders',
          width: 120,
          render: (reminders, { patient }) => (
            <ReminderList 
              reminders={reminders} 
              patient={patient}
            />
          ),
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(reminderOptions).map(value => {
            return {
              label: value,
              value
            }
          })
        }, {
          width: 50,
          className: 'action-column',
          render: (_, { patient, notes }) => <ActionItems patient={patient} notes={notes} />
        }]}
      />
    </div>
  )
}