import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  message,
  Form,
  Select,
  Skeleton,
  Upload,
  Button,
} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { getOrder, updateOrder, addOrder, uploadRequisitionFile, getRequisitionFileLink  } from "../../services/order.service";
import "./orderModal.scss";
import { ContinueButton } from "../continueButton/continueButton.component";
import { listLabs } from "../../services/lab.service";
import { Panel } from "../../enums/index.enum";
import { listPatients } from '../../services/patient.service';
import dayjs from 'dayjs';
import { listAppointments } from "../../services/appointment.service";
import Org from "../../enums/org.enum";
import AppointmentStatus from "../../enums/appointmentStatus.enum";

const { Item } = Form;

export const OrderModal = ({
  open,
  setOpen,
  orderId,
  setOrderId,
  onSuccess
}) => {
  const [form] = Form.useForm();
  const [order, setOrder] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [labs, setLabs] = useState();
  const [patients, setPatients] = useState([])
  const [appointments, setAppointments] = useState([])
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [requisitionFile, setRequisitionFile] = useState(null);
  const [requisitionFileLink, setRequisitionFileLink] = useState(null);

  useEffect(() => {
    if (open) {
      fetchLabs();
      fetchPatients();
      if (orderId) {
        fetchOrder(orderId);
      } else {
        setIsLoading(false); // Only stop loading if there's no order to fetch
      }
    }


  }, [open, orderId]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setOrderId(null);
      setOrder(null);
    }
  }, [open]);

  useEffect(() => {
    if (order) {
      fetchAppointments();
      fetchFormValues();
      fetchRequisitionFileLink();
    }
  }, [order]);

  useEffect(() => {
    if (appointments.length > 0 && order?.appointment) {
      setSelectedAppointment(order.appointment);
    }
  }, [appointments]);


  const handleAppointmentChange = (value) => {
    setSelectedAppointment(value);
  };

  const handleFileChange = ({ file }) => {
    setRequisitionFile(file);
  };


  const fetchFormValues = () => {
    if (!order) return;

    let values = {
      panel: order.panel,
      plan: order.plan,
      reqId: order.reqId,
      reqLink: order.reqLink,
      lab: order.lab,
      addOns: order.addOns?.join(","),
      appointment: order.appointment 
    };

    form.setFieldsValue(values);
  };

  const fetchRequisitionFileLink = async () => {
    if (order && order.reqFileName) {
      const link = await getRequisitionFileLink(order.reqFileName);
      setRequisitionFileLink(link);
    } else {
      setRequisitionFileLink(null);
    }
  };

  const fetchLabs = async () => {
    setLabs(await listLabs());
  };

  const fetchPatients = async () => {
    const fetchedPatients = await listPatients({
      select: '_id firstName lastName',
      filter: {
        org: Org.INSTALAB,
        firstName: {
          $ne: null
        },
        email: {
          $ne: null
        }
      },
      populate: []
    })
    setPatients(sortPatients(fetchedPatients))
  }

  const sortPatients = (unsortedPatients) => {
    return unsortedPatients.sort((a, b) => {
      const nameA = (a.firstName + a.lastName).toLowerCase();
      const nameB = (b.firstName + b.lastName).toLowerCase();
      
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
  }

  const fetchAppointments = async () => {
    setAppointments(await listAppointments({
      select: '_id patients start',
      filter: {
        patients: {
          $in: order?.patient
        },
        status: {
          $in: [AppointmentStatus.CONFIRMED, AppointmentStatus.COLLECTED, AppointmentStatus.COMPLETE]
        }
      },
      populate: [],
      sort: '-start'
    }))
  }

  const onCancel = () => {
    setAppointments([]);
    setSelectedAppointment(null);
    setOpen(false);
    form.resetFields();
  };

  const fetchOrder = async () => {
    setIsLoading(true);
    if (orderId) {
      setOrder(await getOrder(orderId, {
        select: '_id patient panel lab reqId reqLink reqFileName appointment addOns',
        populate: [{
          path: 'patient',
          select: '_id firstName lastName'
        }]
      }));
    } else {
      setOrder(null)
    }
    setIsLoading(false);
  };

  const onOk = async () => {
    setIsSubmitting(true);
    try {
      // Retrieve form values and log them
      const formValues = form.getFieldsValue();
  
      // Destructure the form values
      const { patient, panel, reqId, reqLink, lab, addOns } = formValues;
  
      // Prepare the params object and ensure addOns is correctly handled
      const params = {
        patient,
        panel, 
        lab,
        reqId,
        reqLink,
        addOns: addOns ? addOns.split(",") : [],
        appointment: selectedAppointment ? selectedAppointment : null,
      };
    
      // Handle file upload if a file is selected
      if (requisitionFile) {
        try {
          const uploadResponse = await uploadRequisitionFile(requisitionFile);
          params.reqFileName = uploadResponse.fileName;
        } catch (uploadError) {
          console.error("Error uploading requisition file:", uploadError);
          message.error("Failed to upload requisition file.");
          setIsSubmitting(false);
          return; // Exit the function on upload error
        }
      }
  
      // Save or update the order
      try {
        const response = orderId ? await updateOrder(orderId, params) : await addOrder(params);
        if (onSuccess) {
          onSuccess(response);
        }
        setSelectedAppointment(null);
        setOpen(false);
        form.resetFields();
        message.info("Order saved successfully.");
      } catch (saveError) {
        console.error("Error saving order:", saveError);
        message.error("Failed to save order.");
      }
    } catch (err) {
      console.error("Error in onOk function:", err);
      message.error("Failed to save order.");
    }
    setIsSubmitting(false);
  };

  return (
    <Modal 
      open={open} 
      title={order ? <>Order for {order.patient.firstName} {order.patient.lastName}</> : 'Add Order'}
      onCancel={onCancel}
      afterClose={onCancel}
      okText={ order ? 'Save Order' : 'Add Order'}
      width={700}
      className="order-modal"
      footer={[
        <ContinueButton
          onClick={onOk}
          text={ order ? 'Save Order' : 'Add Order'}
          isSubmitting={isSubmitting}
        />
      ]}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (      
        <Form
          form={form}
          onFinish={onOk}
          layout='vertical'
        >   
          {/* Patient */}
          {!order && (
            <Item 
              label="Patient" 
              name="patient"
              rules={[{ 
                required: true, 
                message: 'Select patient'
              }]}
            >
              <Select 
                placeholder="Select patient..."
                options={patients?.map(patient => {
                  return {
                    label: `${patient.firstName} ${patient.lastName}`,
                    value: patient._id
                  }
                })}
                optionFilterProp="children"
                filterOption={(input, option) => 
                  option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
                }
                showSearch
              />
            </Item>
          )}

          {/* Panel */}
          <Item 
            label="Panel"
            name={"panel"}
          >
            <Select
              placeholder={"Panel"}
              options={Object.entries(Panel).map(([key, value]) => ({key: key, label: value, value: value}))}
              optionFilterProp="children"
              showSearch
            />
          </Item>

          {/* Lab */}
          <Item 
            label="Lab"
            name={'lab'}
          >
            <Select 
              placeholder={'Lab'}
              options={labs?.map(lab => ({
                label: lab.name,
                value: lab._id
              }))}
              showSearch
              filterOption={(input, option) => 
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Item>

          <Item 
            label="Req ID"
            name={"reqId"}
          >
            <Input
              placeholder={"Requisition Id"}
            />
          </Item>

          <Item
            label={<>
              Req File {requisitionFileLink && <> - <a href={requisitionFileLink} className='cta-link' target="_blank" rel="noopener noreferrer">Download File</a></>}
            </>}
          >
            <Upload 
              beforeUpload={() => false} 
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx,.jpg,.png"
              multiple="true"
            >
              <Button icon={<UploadOutlined />}>Upload File</Button>
            </Upload>
          </Item>

          <Item 
            label="Req Link"
            name={"reqLink"}
          >
            <Input
              placeholder={"Requisition Link"}
            />
          </Item>

          <Item 
            label="Add-ons"
            name={"addOns"}
          >
            <Input
              placeholder={"Add-ons"}
            />
          </Item>

          <Item 
            label="Instalab Collection?"
            name={'appointment'}
          >
            <Select
              placeholder={"Select Appointment"}
              value={selectedAppointment}
              onChange={handleAppointmentChange}
              options={appointments.map(appointment => {
                return {
                  label: appointment.start ? dayjs(appointment.start).format('MMM D, YYYY') : 'Getlabs TBD', 
                  key: appointment._id, 
                  value: appointment._id
                }
              })}
              showSearch
            />
          </Item>
        </Form>
      )}
    </Modal>
  )
};
