import { Button, Col, Row, Timeline } from 'antd'
import { useNavigate } from 'react-router-dom'
import PosthogHelper from '../../helpers/posthog.helper'
import { useRef, useEffect, useContext, useState } from 'react'
import './home.scss'
import EventCode from '../../enums/eventCode.enum'
import FlowType from '../../enums/flowType.enum'
import { PgFooter } from '../pgFooter/pgFooter.component'
import { Overview } from '../overview/overview.component'
import { Questions } from '../questions/questions.component'
import { TestCatalog } from '../testCatalog/testCatalog.component'
import { ProductTestimonials } from '../productDetail/productTestimonials/productTestimonials.component'
import { UserContext } from '../../contexts/user.context'
import { ReactComponent as BloodSvg} from "../../assets/svg/blood_drop.svg"
import { ReactComponent as CTSvg} from "../../assets/svg/tac.svg"
import { ReactComponent as DrSvg} from "../../assets/svg/doctor-3.svg"
import { addSlack } from "../../services/slack.service"
import { PopupModal } from "react-calendly";
import { getMembershipTypeByCode } from '../../services/membershipType.service'
import MembershipTypeCode from '../../enums/membershipTypeCode.enum'
import { MembershipPlan } from '../membershipPlan/membershipPlan.component'
import { ReactComponent as FoodSvg} from "../../assets/svg/food-utensils.svg"
import { ResearchCard } from '../researchCard/researchCard.component'
import { BlogData } from '../../data/blog.data'
import { DownOutlined, BellFilled } from '@ant-design/icons';
import { SubscribeModal } from "../subscribeModal/subscribeModal.component";

export const Home = () => {
  const navigate = useNavigate()
  const testingRef = useRef(null)
  const membershipRef = useRef(null)
  const { currentUser } = useContext(UserContext)
  const [openCalendly, setOpenCalendly] = useState()  
  const [membership, setMembership] = useState();
  const [hhAddOn, setHHAddOn] = useState();
  const [openSubscribe, setOpenSubscribe] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const membershipData = await fetchMembership();
      const hhAddOnData = await fetchHHAddOn();
      
      setMembership(membershipData);
      setHHAddOn(hhAddOnData);
    };
  
    fetchData();
  }, []);
  

  const fetchMembership = async () => {
    return await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY_12MO);
  }

  const fetchHHAddOn = async () => {
    return await getMembershipTypeByCode(MembershipTypeCode.HEART_HEALTH);
  }

  useEffect(() => {
    trackPosthog()
  }, [currentUser])

  const trackPosthog = () => {
    PosthogHelper.track(currentUser, EventCode.VISIT_HOME_V2)
  }

  const onChat = () => {
    window.FrontChat("show");
  };

  const onQuiz = () => {
    navigate(`/flow/${FlowType.QUIZ}`)
  }

  const getStarted = async () => {
    try {
      await addSlack({ 
        message: 'User Clicked HOME - Main CTA',
        channel: process.env.REACT_APP_PATIENT_SLACK
      });
    } catch (error) {
      console.error('Failed to log message to Slack:', error);
    }

    navigate(`/longevity-panel`)
  }

  const userJourneys = [
    {
      key: 1,
      title: <>Longevity Blood Panel</>,
      header: false,
      dot: <BloodSvg className="svg" style={{color: "#FF9999" }} />,
      description: "Detected elevated ApoB, highlighting hidden cardiovascular risk. Recommended a coronary calcium scan for deeper insights.",

    },
    {
      key: 2,
      title: <>Coronary Calcium Scan</>,
      header: false,
      dot: <CTSvg className="svg" style={{color: 'lightblue'}} />,
      description: "Revealed a high CAC score of 942, indicating significant plaque buildup in coronary arteries. Recommended CT Coronary Angiogram to assess blockage severity.",
    },
    {
      key: 3,
      title: <>CT Coronary Angiogram</>,
      header: false,
      dot: <CTSvg className="svg" style={{color: 'lightblue'}} />,
      description: "Uncovered over 75% blockage in two major arteries, signaling a very high risk of heart attack.",
    },
    {
      key: 4,
      title: <>Bypass Surgery</>,
      header: false,
      dot: <DrSvg className="svg" style={{color: 'darkblue'}} />,
      color: 'black',
      description: "Prompt referral to a top cardiologist led to life-saving bypass surgery, dramatically reducing risk of a cardiac event.",
    }
  ]

  const user2Journeys = [
    {
      key: 1,
      title: <>DEXA Scan + Longevity Blood Panel</>,
      header: false,
      dot: <CTSvg className="svg" style={{color: 'lightblue'}} />,
      description: "Identified osteoporosis and severe nutrient deficiencies despite supplementation. Recommended celiac disease screening.",
    },
    {
      key: 3,
      title: <>Celiac Disease Screening</>,
      header: false,
      dot: <BloodSvg className="svg" style={{color: "#FF9999" }} />,
      description: "Detected high celiac antibodies (anti-TTG), leading to a referral for gastroenterology evaluation.",
    },
    {
      key: 2,
      title: <>Started Gluten-free Diet</>,
      dot: <FoodSvg className="svg" style={{color: 'lightgray'}} />,
      description: "Post-endoscopy diagnosis of celiac disease led to a switch to a gluten-free diet and specialized supplements.",
    },
    {
      key: 5,
      title: <>Follow-up Tests</>,
      header: false,
      dot: <BloodSvg className="svg" style={{color: "#FF9999" }} />,
      description: "Follow-up tests with Instalab showed optimized nutrient levels without any supplementation needed and improved cardiovascular health post-diet change.",

    }
  ]

  const testimonials = [
    {
      name: "Gustaf Alstromer",
      title: "Partner @ Y Combinator",
      image: "/img/gustaf.jpg",
      webp: "/img/gustaf.webp",
      text: "Staying on top of your health is a must for the best founders. Instalab gave me a clear picture of where I stand and what to focus on. It’s an invaluable tool for anyone serious about improving their health and longevity."
    },
    {
      name: "Jason Goldman",
      title: "Filmmaker @ Wild Growth Media",
      image: "/img/jason.jpg",
      webp: "/img/jason.webp",
      text: "Thanks to Instalab, I discovered I had early osteoporosis. Their team guided me through testing and treatment, helping me uncover the source: celiac disease. I feel like I finally have control over my health."

    }, 
    {
        name: "Ed Suh",
        title: "MD @ Alpine Ventures ",
        image: "/img/ed.jpg", 
        webp: "/img/ed.webp",
        text: "Instalab’s comprehensive approach blew me away. The reports focus on the issues that matter most to me, with intuitive summaries and clear explanations of where I stand on the risk curve."
    },
    {
      name: "Kat Manalac",
      title: "Partner @ Y Combinator",
      image: "/img/kat.jpg",
      webp: "/img/kat.webp",
      text: "As a working parent, I struggle to make time for my health. Instalab made it simple. Their labs gave me clear, actionable insights and a game plan that fits into my busy life. I finally feel proactive about my health."
  },

  ]

  const faqs = [
    {
      question: "How do I use my HSA/FSA with Instalab?",
      answer: `Instalab is eligible for HSA/FSA reimbursement. You can use your HSA/FSA card for payment. If you don't have one, we can provide an itemized receipt for you to submit for reimbursement.`
    },
    {
      question: "What is covered by my HSA/FSA?",
      answer: `Everything at Instalab is covered by your HSA/FSA. This includes all tests, membership fees, and consultations with our physicians.`
    },
    {
      question: `What tests should I be doing to stop on top of my health?`,
      answer: <>Each person is different. The right set of tests is based on your genetics, your past medical history and much more. Take <a onClick={() => onQuiz() } className="cta-link">this short quiz</a> to find out which tests are best for you.</>,
    },   
    {
      question: `Where is Instalab currently available?`,
      answer: <>Anywhere in the United States.</>,
    },   
    {
      question: `Does my health insurance cover Instalab?`,
      answer: `Insurance does not cover Instalab services. However, if you have a Health Savings Account (HSA) or Flexible Spending Account (FSA), you may be eligible for reimbursement. We recommend using your HSA/FSA card for this purpose. If you don’t have one, we can provide an itemized receipt for you to submit for reimbursement.`,
    }, 
    {
      question: `Can Instalab replace my primary care provider?`,
      answer: `Instalab specializes in prevention-based testing and treatments and is not intended for emergency or immediate health issues. While we offer consultations with our physicians, we strongly believe in the importance of continuity of care. If you have a primary care provider that you trust, we recommend maintaining that relationship. We are happy to collaborate with them to ensure you receive comprehensive and well-rounded medical care.`,
    }, 
    {
      question: `What background do Instalab physicians have?`,
      answer: <>At Instalab, each of our physicians is board-certified in various specialties and brings a wealth of experience in guiding patients toward their health goals. They stay current with the latest research and are not just experts but also skilled listeners. Our physicians strive to understand your unique situation and collaborate with you to ensure that your care is aligned with your individual goals and preferences.</>,
    }, 
    {
      question: `Can I request tests not currently in your test catalog?`,
      answer: <>Definitely, please email concierge@instalab.com with your request.</>,
    }, 

    {
      question: `Can I expense Instalab to my company?`,
      answer: `Absolutely. Your company can and should cover the cost of this service. Your health directly impacts the success of your business. Just as executive coaching sessions and other professional services are justified expenses, Instalab services should be viewed similarly. If there are any reservations, we are more than willing to discuss the value of this investment with your company’s investors. It is a minimal cost for potentially significant returns.`
    }, 
    {
      question: `Can I gift an Instalab membership to my family or friends?`,
      answer: <>Yes, Instalab makes a thoughtful gift for both family and friends. If you're interested in gifting Instalab, <a className="cta-link" onClick={() => navigate('/flow/gift')}>simply fill out you and your recipient's info</a> and we'll send it in a custom digital e-card on the date you choose.</>
  }]

  const getLatestResearch = () => {
    return BlogData.slice(0, 3);
  };

  const MembershipPlans = () => {

    return (
      <Row gutter={36} className="membership-plans-container">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          className="membership-plan"
        >
          <MembershipPlan
            membershipTypeCode={MembershipTypeCode.LONGEVITY_12MO}
            path={`/flow/${FlowType.MEMBER}`}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          className="membership-plan"
        >

          <MembershipPlan
            membershipTypeCode={MembershipTypeCode.HEART_HEALTH}
            path={`/heart`}
          />
      
        </Col>
   

      </Row>
    );
  };
  
  const MemberSpotlights = () => {
    return  (   
      <Row   className="member-spotlights">

        <Col
            xs={24}
            sm={24}
            md={24}
            lg={11}
            xl={11}
            xxl={11} 
            className="member-spotlight"
          >

          <div className="timeline-title">

            <div className="member-info" style={{textAlign:"center", marginBottom:15}}>
              <div className="member-name">Marshall F. (49 years old)</div>

            </div>
          </div>


          <div className="quote-container">Instalab likely saved my life. Their testing identified critical issues that likely prevented an imminent heart attack. I'm incredibly grateful.</div>

          <div className="timeline-reason"><span>Instalab Snapshot</span></div>
          <Timeline mode="left" className="timeline">
            {userJourneys.map((item) => (
              <Timeline.Item 
                dot={item.dot} 
                color={item.color} 
                label={item.label}
              >
                <div className="timelime-item-title">{item.title}</div>
                <div className="timelime-item-description">{item.description}</div>
              
              </Timeline.Item>
            ))}
          </Timeline>

        </Col>
        
        <Col            
            xs={0}
            sm={0}
            md={0}
            lg={1}
            xl={1}
            xxl={1}>
        </Col>

        <Col
            xs={24}
            sm={24}
            md={24}
            lg={11}
            xl={11}
            xxl={11}
            className="member-spotlight"
          >

          <div className="timeline-title">

            <div className="member-info" style={{textAlign:"center", marginBottom:15}}>
              <div className="member-name">Jason G. (40 years old)</div>
            </div>
          </div>

          <div className="quote-container">I discovered I had early osteoperosis through Instalab and their team helped me get the right treatemnt and testing to figure out the source which turned out to be celiac disease!</div>


          <div className="timeline-reason"><span>Instalab Snapshot</span></div>
          <Timeline mode="left" className="timeline">
            {user2Journeys.map((item) => (
              <Timeline.Item 
                dot={item.dot} 
                color={item.color} 
                label={item.label}
              >
                <div className="timelime-item-title">{item.title}</div>
                <div className="timelime-item-description">{item.description}</div>
              
              </Timeline.Item>
            ))}
          </Timeline>

        </Col>


      </Row>
    )
  }

  const scrollToTests = () => {
    testingRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      {membership && <div className="home">
        <PopupModal
          open={openCalendly}
          url="https://calendly.com/d/cmhv-xmp-7h4?hide_gdpr_banner=1"
          onModalClose={() => setOpenCalendly(false)}
          rootElement={document.getElementById("root")}
          pageSettings={{
            hideEventTypeDetails: true,
            hideLandingPageDetails: true,
          }}
        />
        
        <SubscribeModal
          open={openSubscribe}
          setOpen={setOpenSubscribe}
        />

        <div className="introduction">
          <div className="max-width">
            <Row 
              gutter={48}
              className="introduction-row"
            >
              <Col 
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
                className="introduction-main-col"
              >
                <div className="introduction-title">
                  Proactive Health<br/>Starts Here.
                </div>

                <div className="introduction-description">
                The best advanced diagnostics and personalized care to detect disease early and help you live longer.     
              
                </div>

                <Button
                  className="join-btn"
                  type="primary"
                  onClick={getStarted}
                >
                 Start with Longevity Panel
                </Button>

                <Button
                  className="learn-btn"
                  type="default"
                  onClick={scrollToTests}
                >
                  Browse Tests
                </Button>


              </Col>
            </Row>
          </div>
        </div>

        <div className="stats-section dark-bg">
          <Row justify="center" align="middle">
            <Col xs={12} sm={8} md={6} className="metric-col">
              <div className="metric animate-in">
                <div className="metric-icon">🏥</div>
                <div className="metric-number highlight">FSA/HSA</div>
                <div className="metric-label">Accepted</div>
              </div>
            </Col>

            <Col xs={12} sm={8} md={6} className="metric-col">
              <div className="metric animate-in">
                <div className="metric-icon">👥</div>
                <div className="metric-number highlight">18,000+</div>
                <div className="metric-label">Patients Treated</div>
              </div>
            </Col>

            <Col xs={12} sm={8} md={6} className="metric-col">
              <div className="metric animate-in">
                <div className="metric-icon">🏢</div>
                <div className="metric-number highlight">100+</div>
                <div className="metric-label">Corporate Clients</div>
              </div>
            </Col>

            <Col xs={12} sm={8} md={6} className="metric-col">
              <div className="metric animate-in">
                <div className="metric-icon">⭐</div>
                <div className="metric-number highlight">4.9/5</div>
                <div className="metric-label">Patient Rating</div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="testing" ref={testingRef}>

          <Overview 
            title="Popular Tests"
            description={<>Explore the most effective ways to early detect conditions like heart disease and cancer.</>}
          />
            
          <div className="test-catalog-container">
          <TestCatalog/>
          </div>

        </div>

        <div className="testimonials dark-bg">
          <Overview title="Hear From Real Patients"/>
          <div className="testimonials-container">
            <ProductTestimonials testimonials={testimonials}/>
          </div>
        </div>

        {/* <div className="member-spotlights-container">
          <Overview title="Patient Spotlights"/>
          <MemberSpotlights/>
        </div>    */}

        <div className="memberships-container" ref={membershipRef}>

            <Overview 
              title={<>The Perfect Health Plan</>}
              description={<div className="overview-subtitle">Gain a partner truly dedicated to your long-term health.</div>}
            />
             
            <MembershipPlans/>
        </div>
    


        <div className="research-section section dark-bg">
          <Overview 
            title="The Instalab Brief"
            description="Stay ahead with our weekly newsletter, offering actionable insights from the latest health research that could help you live longer."
          />

          <div className="research-container">
            <Row gutter={[24, 24]}>
              {getLatestResearch().map(({ id, title, description, publishedAt, readTime }) => (
                <Col xs={24} sm={24} md={8} lg={8} xl={8} key={id}>
                  <ResearchCard
                    title={title}
                    description={description}
                    readTime={readTime}
                    publishedAt={publishedAt}
                    onClick={() => navigate(`/research/${id}`)}
                  />
                </Col>
              ))}
            </Row>
            <div className="view-more-wrapper">
              <Button
                type="primary"
                className="subscribe-btn"
                icon={<BellFilled />}
                onClick={() => {
                  setOpenSubscribe(true)
                  PosthogHelper.track(currentUser, '[research] click subscribe btn', true)
                }}
              >
                Subscribe
              </Button>
              <Button type="default" className="view-btn" onClick={() => navigate('/research')}>
                View More  <DownOutlined className="view-btn-icon" />
              </Button>
            </div>
          </div>
        </div>

        <div className="faq">
          <Overview
            title="Frequently Asked Questions"
            description={<>If you have any questions, <a onClick={onChat}>we'd love to chat with you</a>.</>}
          />

          <Questions items={faqs}/>
        </div>

        <PgFooter />

   
      </div>}
    </>
  )
}
