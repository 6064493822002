import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Input, Button, Select, message, Checkbox } from 'antd'
import Gender from '../../enums/gender.enum'
import "./patientForm.scss"
import { addPatient, getPatient, updatePatient } from '../../services/patient.service';
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper'
import PhoneInput from '../phoneInput/phoneInput.component';
import DateInput from '../dateInput/dateInput.component';

const { Item } = Form

export const PatientForm = ({ title, open, setOpen, onSuccess, patientId, select, populate=[], requiredFields=[] }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()
  const [isAddOnlyProcessing, setIsAddOnlyProcessing] = useState(false)
  const [isInviteProcessing, setIsInviteProcessing] = useState(false)
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    if (open) {
      if (patientId) setPatientFields()
    } else {
      form.resetFields()
      setHasAttempt(false)
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const hasFormError = async () => {
    try {
      await form.validateFields();
    } catch ({ errors, values }) {
      form.setFieldsValue(values)
      return true
    }
    return false
  }

  const setPatientFields = async () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      dob,
      gender,
      isAthlete,
    } = await getPatient(patientId, {
      select: 'firstName lastName email phone dob gender isAthlete',
      populate: [],
    })

    form.setFieldsValue({
      firstName,
      lastName,
      email,
      phone,
      dob,
      gender,
      isAthlete,
    })
  }

  const onFail = () => {
    console.log("on fail", new Date())
    setHasAttempt(true)
    message.error('Enter valid data')
  }

  const onOk = async (skipInvite) => {
    setIsSubmitting(true)
    if (skipInvite)
        setIsAddOnlyProcessing(true)
    else 
      setIsInviteProcessing(true)

    try {
      if (await hasFormError()) {
        message.error('Enter valid data')
        setIsSubmitting(false)
        return 
      }

      const {
        firstName,
        lastName,
        email,
        phone,
        dob,
        gender,
        isAthlete,
      } = form.getFieldsValue()
      
      const params = {
        fields: {
          firstName,
          lastName,
          email,
          phone,
          dob,
          gender,
          isAthlete,
        },
        select,
        populate,
        skipInvite
      }

      const response = patientId ? await updatePatient(patientId, params) : await addPatient(params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(patientId ? 'Patient updated' : 'Patient added')
      setOpen(false)
      form.resetFields()
    } catch (err) {
      console.log(err)
      let msg = patientId ? 'Failed to update patient' : 'Failed to add patient'
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email'
      } else if (err.response?.data?.message) {
        msg = err.response.data.message
      }
      message.error(msg)
    } finally {
      setIsSubmitting(false)
      setIsAddOnlyProcessing(false)
      setIsInviteProcessing(false)
  
    }
  }

  const isFieldRequired = (fieldName) => {
    return requiredFields.includes(fieldName)
  }

  return (
    <Modal 
      open={open} 
      title={title ? title : patientId ? 'Edit Patient' : 'Add Patient'}
      onCancel={onCancel}
      footer={null}
      className="patient-form"
    >

      <Form
        form={form}
        onFinish={onOk}
        onFinishFailed={onFail}
        layout='vertical'
      >

      <Item 
          label="First Name"
          name="firstName"
          rules={[
            ...(isFieldRequired('firstName') ? [RuleHelper.isRequired] : [])
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="First Name" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Last Name"
          name="lastName"
          rules={[
            ...(isFieldRequired('lastName') ? [RuleHelper.isRequired] : [])
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Last Name" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Email Address"
          name="email"
          onInput={e => e.target.value = e.target.value.toLowerCase()}
          rules={[
            ...(isFieldRequired('email') ? [RuleHelper.isRequired] : []),
            RuleHelper.isEmail,
            RuleHelper.isUniqueEmail({
              fieldName: 'email',
              userId: patientId,
            })
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Email Address" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Phone Number"
          name="phone"
          rules={[
            ...(isFieldRequired('phone') ? [RuleHelper.isRequired] : []),
            RuleHelper.isPhone,
          ]}
          validateTrigger='onSubmit'
        >
          <PhoneInput 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>


        <Item 
          label="Biological Sex"
          name="gender"
          rules={[
            ...(isFieldRequired('gender') ? [RuleHelper.isRequired] : [])
          ]}
          validateTrigger='onSubmit'
        >
          <Select
            placeholder="Select Sex"
            showSearch
            options={Object.values(Gender).map(gender => {
              return {
                label: gender,
                value: gender,
              }
            })}
            onSelect={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Date of Birth"
          name="dob"
          rules={[
            ...(isFieldRequired('dob') ? [RuleHelper.isRequired] : []),
            RuleHelper.isDate,
          ]}
          validateTrigger='onSubmit'
        >
          <DateInput 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item
          label="Is Pro Athlete?"
          name="isAthlete"
          valuePropName="checked"
        >
          <Checkbox>Yes</Checkbox>
        </Item>


        <Button
          className="submit-btn"
          type='primary'
          disabled={isSubmitting}
          loading={isInviteProcessing}
          onClick={() => {
            form.validateFields()
              .then(() => onOk(false))
              .catch(() => onFail())
          }}
        >
          {patientId ? 'Save' : 'Send Invite'}
        </Button>

        {!patientId && (
          <Button
            className="add-only-btn"
            type="default"
            disabled={isSubmitting}
            loading={isAddOnlyProcessing}
            onClick={() => {
              form.validateFields()
                .then(() => onOk(true))
                .catch(() => onFail())
            }}
          >
            Add Without Invite
          </Button>
        )}
      </Form>
    </Modal>
  )
}